// import React from 'react'
// const TradeFooterTable = () => {

//   return (
//     <>
//       <div className='container-fluid'>
//                 <div className='footer-trade-table'>
//                     <nav>
//                         <div class="nav nav-tabs   ftt-nav" id="nav-tab" role="tablist">
//                             <button class="nav-link active ftt-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Pending</button>
//                             <button class="nav-link ftt-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Open</button>
//                             <button class="nav-link ftt-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Completed</button>
//                         </div>
//                     </nav>

//                     <div class="tab-content ftt-content-body p-3" id="nav-tabContent">
//                         <div class="tab-pane active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
//                             <div className='col-md-12'>
//                                 <div className='market-table'>
//                                     <table className="table mt-table">
//                                         <thead>
//                                             <tr>
//                                                 <th scope="col">Coin Name</th>
//                                                 <th scope="col">Coin Price (USDT)</th>
//                                                 <th scope="col">1 Hour Price</th>
//                                                 <th scope="col">7 Hour Price</th>
//                                                 <th scope="col">24 Hour Price</th>
//                                                 <th scope="col">Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>

//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="tab-pane " id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
//                             <div className='col-md-12'>
//                                 <div className='market-table'>
//                                     <table className="table mt-table">
//                                         <thead>
//                                             <tr>
//                                                 <th scope="col">Coin Name</th>
//                                                 <th scope="col">Coin Price (USDT)</th>
//                                                 <th scope="col">1 Hour Price</th>
//                                                 <th scope="col">7 Hour Price</th>
//                                                 <th scope="col">24 Hour Price</th>
//                                                 <th scope="col">Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>

//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>

//                         <div class="tab-pane " id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
//                         <div className='col-md-12'>
//                                 <div className='market-table'>
//                                     <table className="table mt-table">
//                                         <thead>
//                                             <tr>
//                                                 <th scope="col">Coin Name</th>
//                                                 <th scope="col">Coin Price (USDT)</th>
//                                                 <th scope="col">1 Hour Price</th>
//                                                 <th scope="col">7 Hour Price</th>
//                                                 <th scope="col">24 Hour Price</th>
//                                                 <th scope="col">Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>

//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div >
//             </div>

//     </>
//   )
// }

// export default TradeFooterTable

// import React, { useState, useEffect } from 'react';
// import { base_url } from '../config/config';

// const TradeFooterTable = () => {
//     const [orders, setOrders] = useState([]);
//     const [activeTab, setActiveTab] = useState('Pending'); // Default active tab

//     useEffect(() => {
//         const Token = localStorage.getItem('token');
//         const fetchOrders = async () => {
//             try {
//                 const response = await fetch(`${base_url}/trading_orders`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         authorization: Token
//                     },
//                     body: JSON.stringify({ status: activeTab.toUpperCase() })
//                 });
//                 if (response.success === 1) {
//                     throw new Error('Network response was not ok.');
//                 }
//                 const data = await response.json();
//                 setOrders(data);
//             } catch (error) {
//                 console.error('Error fetching orders:', error);
//             }
//         };

//         fetchOrders();
//     }, [activeTab]); // Fetch orders whenever activeTab changes

//     const handleTabChange = (tabName) => {
//         setActiveTab(tabName);
//     };

//     return (
//         <>
//             <div className='container-fluid'>
//                 <div className='footer-trade-table'>
//                     <nav>
//                         <div className="nav nav-tabs ftt-nav" id="nav-tab" role="tablist">
//                             <button
//                                 className={`nav-link ${activeTab === 'Pending' ? 'active' : ''} ftt-link`}
//                                 id="nav-pending-tab"
//                                 onClick={() => handleTabChange('Pending')}
//                                 aria-selected={activeTab === 'Pending'}
//                             >
//                                 Pending
//                             </button>
//                             <button
//                                 className={`nav-link ${activeTab === 'Open' ? 'active' : ''} ftt-link`}
//                                 id="nav-open-tab"
//                                 onClick={() => handleTabChange('Open')}
//                                 aria-selected={activeTab === 'Open'}
//                             >
//                                 Open
//                             </button>
//                             <button
//                                 className={`nav-link ${activeTab === 'Completed' ? 'active' : ''} ftt-link`}
//                                 id="nav-completed-tab"
//                                 onClick={() => handleTabChange('Completed')}
//                                 aria-selected={activeTab === 'Completed'}
//                             >
//                                 Completed
//                             </button>
//                         </div>
//                     </nav>

//                     <div className="tab-content ftt-content-body p-3" id="nav-tabContent">
//                         <div className={`tab-pane ${activeTab === 'Pending' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-pending-tab">
//                             {/* Tab content for Pending */}
//                             {orders.map((order, index) => (
//                                 <div key={index}>
//                                     {/* Render each pending order */}
//                                 </div>
//                             ))}
//                         </div>
//                         <div className={`tab-pane ${activeTab === 'Open' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-open-tab">
//                             {/* Tab content for Open */}
//                             {orders.map((order, index) => (
//                                 <div key={index}>
//                                     {/* Render each open order */}
//                                 </div>
//                             ))}
//                         </div>
//                         <div className={`tab-pane ${activeTab === 'Completed' ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-completed-tab">
//                             {/* Tab content for Completed */}
//                             {orders.map((order, index) => (
//                                 <div key={index}>
//                                     {/* Render each completed order */}
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default TradeFooterTable;
import React, { useState, useEffect } from 'react';
import { base_url } from '../config/config';
import dateFormat, { masks } from "dateformat";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { NavLink } from 'react-router-dom';
import { Loader } from 'rsuite';


const TradeFooterTable = () => {
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('COMPLETED');

    // console.log('footer-order', orders);

    useEffect(() => {
        const Token = localStorage.getItem('token');
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${base_url}/trading_orders`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: Token
                    },
                    body: JSON.stringify({ status: activeTab.toUpperCase() })
                });
                // console.log('response', response);
                const data = await response.json();
                // console.log('data--->aaaa', data);
                if (data.success === 1) {
                    // const data = await response.json();
                    // console.log('data--->aaaa', data);
                    setOrders(data.orders);

                }

            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [activeTab]);

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };
    // console.log('order---', orders);
    return (
        <>
            <div className='container-fluid'>
                <div className='footer-trade-table'>
                    <nav>
                        <div className="nav nav-tabs ftt-nav" id="nav-tab" role="tablist">
                            <button
                                className={`nav-link ${activeTab === 'PENDING' ? 'active' : ''} ftt-link`}
                                id="nav-pending-tab"
                                onClick={() => handleTabChange('PENDING')}
                                aria-selected={activeTab === 'PENDING'}
                            >
                                Pending
                            </button>
                            <button
                                className={`nav-link ${activeTab === 'PARTIALFILLED' ? 'active' : ''} ftt-link`}
                                id="nav-open-tab"
                                onClick={() => handleTabChange('PARTIALFILLED')}
                                aria-selected={activeTab === 'PARTIALFILLED'}
                            >
                                Open
                            </button>
                            <button
                                className={`nav-link ${activeTab === 'COMPLETED' ? 'active' : ''} ftt-link`}
                                id="nav-completed-tab"
                                onClick={() => handleTabChange('COMPLETED')}
                                aria-selected={activeTab === 'COMPLETED'}
                            >
                                Completed
                            </button>
                        </div>
                    </nav>

                    <div className="tab-content ftt-content-body p-1" id="nav-tabContent">
                        <div className={`tab-pane ${activeTab === 'PENDING' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-pending-tab">
                            {/* Tab content for Pending */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.length > 0 ? orders.map((order, index) => (

                                                (<tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>

                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                </tr>)
                                            )) :
                                                (<tr className='text-center'>
                                                    <td colSpan='6'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'PARTIALFILLED' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-open-tab">
                            {/* Tab content for Open */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.length > 0 ? orders.map((order, index) => (
                                                <tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>

                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                </tr>
                                            ))
                                                :
                                                (<tr className='text-center'>
                                                    <td colSpan='6'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'COMPLETED' ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-completed-tab">
                            {/* Tab content for Completed */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.length > 0 ? orders.map((order, index) => (

                                                <tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>
                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                </tr>
                                            ))
                                                :
                                                (<tr className='text-center'>
                                                    <td colSpan='7'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* //historymodal */}

            <div className='transfer-modal'>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className='modal-content-main'>
                        <div className='modal-header'>
                            <h3>History</h3>
                        </div>
                        <div className='modal-body1'>

                        </div>
                    </div>
                </Modal>
            </div>

        </>
    );
};

export default TradeFooterTable;
