import React, { useEffect, useState } from 'react'
import c1 from '../../assets/icons/m1-icon.png';

import m1 from '../../assets/icons/m1-icon.png';
import m2 from '../../assets/icons/bnb.png';
import m3 from '../../assets/icons/btc.png';
import m4 from '../../assets/icons/m2-icon.png';
import { MdOutlineSearch } from 'react-icons/md';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import OverHeader from './OverHeader';
import { base_url } from '../../config/config';
import Skeleton from 'react-loading-skeleton';
import dateFormat from 'dateformat';

const FundingAccount = () => {

    const [funding, setFunding] = useState();
    const [loading, setLoading] = useState(true);
    const [fundData, setFundData] = useState([]);
    const [search, setSearch] = useState('')
    const [hideZero, setHideZero] = useState(false);

    console.log('fundDatafundData-->', fundData);
    const getData = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/userwallet`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                wallet_type: "funding_wallet"
            })
        });
        const data = await response.json();
        if (data.success === 1) {
            setFunding(data.wallets.funding_wallet.balance);
            setFundData(data.wallets.funding_wallet.wallets)
            setLoading(false);
        } else {
            console.error('Error:', response.status);
        }
    };

    // const FundingAccount = async () => {
    //     const token = localStorage.getItem("token");
    //     const response = await fetch(`${base_url}/userwallet`, {
    //         method: 'POST',
    //         headers: {
    //             Authorization: token,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             wallet_type: "funding_wallet",
    //         })
    //     });
    //     const data = await response.json();
    //     if (data.success === 1) {
    //         setFundData(data.wallets.main_wallet.wallets)

    //     } else {
    //         console.error('Error:', response.status);
    //     }
    // };


    useEffect(() => {
        getData();

    }, [])
    return (
        <>
            <OverHeader />
            <section>
                <div className='container'>
                    <div className='overview-tab-content'>
                        <div className='over-content-01'>
                            <div className='over-01-left'>
                                <div className='over-pagetitle-01'>
                                    <h2>Funding Account History</h2>
                                </div>
                                <div className='d-gap d-flex align-items-center'>
                                    <div className='img-price-01 d-flex align-items-center'>
                                        <img src={c1} alt='coin-image' />
                                        <span>{((funding))} USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className='over-01-right'>

                                <div className=''></div>
                                <div className='over-01-innerdiv'>
                                    {/* <div className='form-group form-group-flex'>
                                        <input id="bls" type="checkbox" onChange={(e) => setHideZero(e.target.checked)} />
                                        <label htmlFor='bls' className='ms-2'>Hide Zero Balances</label>
                                    </div> */}
                                    <div className='form-group'>
                                        <div className='slider-top-srh'>
                                            <input type="search" placeholder='Search Coin Name' onChange={(e) => setSearch(e.target.value)} />
                                            <MdOutlineSearch className='srh-icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='market-table'>

                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Token Name</th>
                                            <th scope="col">USD Amount</th>
                                            <th scope="col">USD Price</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>

                                    {/* {loading ? (
                                    <Skeleton count={1} height={50} baseColor="#202020" highlightColor="#444" />
                                ) : ( */}

                                    <tbody>
                                        {fundData.filter((item) => {
                                            return (search.toUpperCase() === ''
                                                || item?.tokenname.toUpperCase().includes(search.toUpperCase()))
                                                && (!hideZero || item?.balance > 0);

                                        }).map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className='td-1'>
                                                        <div className='markit-td1'>
                                                            <span className='coin-name'>{item ? item.tokenname : ''}</span>
                                                        </div>
                                                    </td>
                                                    <td>{item ? item.usd_amount : ''}</td>
                                                    <td>{item ? item.usd_price : ''}</td>
                                                    <td>{item ? item.balance : ''}</td>
                                                    <td>{dateFormat(item.createdAt, "dd-mm-yy,hh:mm:ss TT")}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                {/* <div className='market-table-footer'>
                                    <div className='total-page'>
                                        <p>1-13 Of 13</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                                <li class="page-item"><a class="page-link page-link1" href=""><GrFormPrevious /></a></li>
                                                <li class="page-item"><a class="page-link page-link1" href="">1</a></li>
                                                <li class="page-item"><a class="page-link page-link1" href=""><GrFormNext /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FundingAccount