// import React, { useContext ,useState} from 'react';
// import { MarketContext } from '../../Marketcontextapi'; // Import the MarketContext from the provider
// // import React, { useState, useEffect } from 'react';
// import { MdOutlineSearch } from "react-icons/md";
// import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
// import { GrFormNext, GrFormPrevious } from "react-icons/gr";
// import { NavLink } from 'react-router-dom';
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
// import "swiper/css";
// import "swiper/css/autoplay";
// import m1 from '../../assets/icons/m1-icon.png';
// import { base_url } from '../../config/config';
// import { ColorRing } from 'react-loader-spinner'; 
// const Markets = () => {
//     const { data, error, loading } = useContext(MarketContext); // Use useContext hook to access context data
    
//         const [searchQuery, setSearchQuery] = useState('');
        



//     const theme =localStorage.getItem('theme');
//     const handleSearchInputChange = (event) => {
//                 setSearchQuery(event.target.value);
//             };
        
//             const filteredData = data.filter(item => {
//                 const searchTerm = searchQuery.toLowerCase();
//                 return (
//                     item.name.toLowerCase().includes(searchTerm) ||
//                     item.symbol.toLowerCase().includes(searchTerm)
//                 );
//             });
        
//             if (loading) {
//                 const spinnerColors = theme === 'dark' ? ['#fff', '#fff', '#fff', '#fff', '#fff'] : ['#000', '#000', '#000', '#000', '#000'];
//                 return (
//                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//                         <ColorRing color={theme === 'dark' ? '#fff' : '#000'} height={80} width={80} colors={spinnerColors} wrapperClass="spinner1" />
//                     </div>
//                 );
//             }
            
//             if (error) {
//                 return <div>Error: {error}</div>;
//             }

//     return (
//         <>
           

//              <section className='market-main-page'>
//                  <div className='container'>
//                      <div className='row'>
//                          <div className='col-md-12 col-sm-12'>
//                              <div className='market-slider'>
//                                  <div className=' slider-header'>
//                                      <div className='slider-top-content'>
//                                          <h4>Market Coins</h4>
//                                          <p>Glorious, not only for your eyes.</p>
//                                      </div>
//                                      <div className='slider-top-srh'>
//                                          <input type="search" placeholder='Search Coin Name ' value={searchQuery} onChange={handleSearchInputChange} />
//                                          <MdOutlineSearch className='srh-icon' />
//                                      </div>
//                                  </div>
//                                  <div className='row'>
//                                      <Swiper
//                                         modules={[Autoplay]}
//                                         spaceBetween={15}
//                                         slidesPerView={4}
//                                         autoplay={{
//                                             delay: 3000,
//                                             disableOnInteraction: false,
//                                         }}
//                                         breakpoints={{
//                                             320: {
//                                                 slidesPerView: 1,
//                                             },
//                                             479: {
//                                                 slidesPerView: 1,
//                                             },
//                                             576: {
//                                                 slidesPerView: 2,
//                                             },
//                                             768: {
//                                                 slidesPerView: 3,
//                                             },
//                                             992: {
//                                                 slidesPerView: 3,
//                                             },
//                                             1200: {
//                                                 slidesPerView: 4,
//                                             },
//                                         }}
//                                     >
//                                         {filteredData.slice(0, 20).map((item, index) => (
//                                             <SwiperSlide key={index}>
//                                                 <div class="card market-card">
//                                                     <div className='mc1'>
//                                                         <div className='mc1-flex'>
//                                                             <img src={item.image} alt="m1-image" />
//                                                             <h5>{item.name}</h5>
//                                                         </div>
//                                                         <div className='mc1-flex mt-2'>
//                                                             <span>≈</span>
//                                                             <h6>{String(item.price).slice(0, 10)}</h6>
//                                                             <div className='up-down-count'>
//                                                                 {item.percent_change_24h > 0 ?
//                                                                     (<td className='up-trade '>{item.percent_change_24h} <span><FaLongArrowAltUp /></span></td>) :
//                                                                     (<td className='down-trade '>{item.percent_change_24h} <span><FaLongArrowAltDown className='' /></span></td>)
//                                                                 }
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className='mc2'>

//                                                     </div>
//                                                 </div>
//                                             </SwiperSlide>
//                                         ))}
//                                     </Swiper>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-md-12'>
//                             <div className='market-table'>
//                                 <table className="table mt-table">
//                                     <thead>
//                                         <tr>
//                                             <th scope="col">Coin Name</th>
//                                             <th scope="col">Coin Price (USDT)</th>
//                                             <th scope="col">1 Hour Price</th>
//                                             <th scope="col">7 Hour Price</th>
//                                             <th scope="col">24 Hour Price</th>
//                                             <th scope="col">Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {filteredData.map((item, index) => (
//                                             <tr key={index}>
//                                                 <td className='td-1'>
//                                                     <div className='markit-td1'>
//                                                         <img src={item.image} alt="" />
//                                                         <span className='coin-name'>{item.name}</span>
//                                                         <span className='coin-tag-name'>{item.symbol}</span>
//                                                     </div>
//                                                 </td>
//                                                 <td>{String(item.price).slice(0, 10)}</td>
//                                                 {item.percent_change_1h > 0 ?
//                                                     (<td className='up-trade '>{item.percent_change_1h} <span><FaLongArrowAltUp /></span></td>) :
//                                                     (<td className='down-trade '>{item.percent_change_1h} <span><FaLongArrowAltDown className='' /></span></td>)
//                                                 }
//                                                 {item.percent_change_7d > 0 ?
//                                                     (<td className='up-trade '>{item.percent_change_7d} <span><FaLongArrowAltUp /></span></td>) :
//                                                     (<td className='down-trade '>{item.percent_change_7d} <span><FaLongArrowAltDown className='' /></span></td>)
//                                                 }
//                                                 {item.percent_change_24h > 0 ?
//                                                     (<td className='up-trade '>{item.percent_change_24h} <span><FaLongArrowAltUp /></span></td>) :
//                                                     (<td className='down-trade '>{item.percent_change_24h} <span><FaLongArrowAltDown className='' /></span></td>)
//                                                 }
//                                                 <td className='action-btn'>
//                                                     <NavLink to={`/trade?symbol=${item.symbol}`}>Trade</NavLink>
//                                                     <NavLink to={`/trade?symbol=${item.symbol}`} className="ms-2">Convert</NavLink>
//                                                     <NavLink to={`/trade?symbol=${item.symbol} `} className="ms-2">Buy/Sell</NavLink>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Markets;


// Markets.js
import React, { useContext ,useState} from 'react';
import { MarketContext } from '../../Marketcontextapi'; // Import the MarketContext from the provider
import { MdOutlineSearch } from "react-icons/md";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "swiper/css";
import "swiper/css/autoplay";
import m1 from '../../assets/icons/m1-icon.png';
import { ColorRing } from 'react-loader-spinner'; 

const Markets = () => {
    const { data, error, loading } = useContext(MarketContext); // Use useContext hook to access context data
    const [searchQuery, setSearchQuery] = useState('');

    const theme =localStorage.getItem('theme');
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter(item => {
        const searchTerm = searchQuery.toLowerCase();
        return (
            item.name.toLowerCase().includes(searchTerm) ||
            item.symbol.toLowerCase().includes(searchTerm)
        );
    });

    if (loading) {
        const spinnerColors = theme === 'dark' ? ['#fff', '#fff', '#fff', '#fff', '#fff'] : ['#000', '#000', '#000', '#000', '#000'];
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <ColorRing color={theme === 'dark' ? '#fff' : '#000'} height={80} width={80} colors={spinnerColors} wrapperClass="spinner1" />
            </div>
        );
    }
    
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <section className='market-main-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12'>
                            <div className='market-slider'>
                                <div className=' slider-header'>
                                    <div className='slider-top-content'>
                                        <h4>Market Coins</h4>
                                        <p>Glorious, not only for your eyes.</p>
                                    </div>
                                    <div className='slider-top-srh'>
                                        <input type="search" placeholder='Search Coin Name ' value={searchQuery} onChange={handleSearchInputChange} />
                                        <MdOutlineSearch className='srh-icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={15}
                                        slidesPerView={4}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            479: {
                                                slidesPerView: 1,
                                            },
                                            576: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            992: {
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                    >
                                        {filteredData.slice(0, 20).map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="card market-card">
                                                    <div className='mc1'>
                                                        <div className='mc1-flex'>
                                                            <img src={item.image} alt="m1-image" />
                                                            <h5>{item.name}</h5>
                                                        </div>
                                                        <div className='mc1-flex mt-2'>
                                                            <span>≈</span>
                                                            <h6>{String(item.price).slice(0, 10)}</h6>
                                                            <div className='up-down-count'>
                                                                {item.percent_change_24h > 0 ?
                                                                    (<td className='up-trade '>{item.percent_change_24h} <span><FaLongArrowAltUp /></span></td>) :
                                                                    (<td className='down-trade '>{item.percent_change_24h} <span><FaLongArrowAltDown className='' /></span></td>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mc2'>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='market-table'>
                                <table className="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col">Coin Price (USDT)</th>
                                            <th scope="col">1 Hour Price</th>
                                            <th scope="col">7 Hour Price</th>
                                            <th scope="col">24 Hour Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td className='td-1'>
                                                    <div className='markit-td1'>
                                                        <img src={item.image} alt="" />
                                                        <span className='coin-name'>{item.name}</span>
                                                        <span className='coin-tag-name'>{item.symbol}</span>
                                                    </div>
                                                </td>
                                                <td>{String(item.price).slice(0, 10)}</td>
                                                {item.percent_change_1h > 0 ?
                                                    (<td className='up-trade '>{item.percent_change_1h} <span><FaLongArrowAltUp /></span></td>) :
                                                    (<td className='down-trade '>{item.percent_change_1h} <span><FaLongArrowAltDown className='' /></span></td>)
                                                }
                                                {item.percent_change_7d > 0 ?
                                                    (<td className='up-trade '>{item.percent_change_7d} <span><FaLongArrowAltUp /></span></td>) :
                                                    (<td className='down-trade '>{item.percent_change_7d} <span><FaLongArrowAltDown className='' /></span></td>)
                                                }
                                                {item.percent_change_24h > 0 ?
                                                    (<td className='up-trade '>{item.percent_change_24h} <span><FaLongArrowAltUp /></span></td>) :
                                                    (<td className='down-trade '>{item.percent_change_24h} <span><FaLongArrowAltDown className='' /></span></td>)
                                                }
                                                <td className='action-btn'>
                                                    <NavLink to={`/trade?symbol=${item.symbol}`}>Trade</NavLink>
                                                    {/* <NavLink to={`/trade?symbol=${item.symbol}`} className="ms-2">Convert</NavLink> */}
                                                    <NavLink to={`/trade?symbol=${item.symbol} `} className="ms-2">Buy/Sell</NavLink>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Markets;
