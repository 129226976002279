import React from 'react'
import download01 from '../../assets/img/download_sec01_img.png'
import mapp from '../../assets/img/Homepage/mobile-app.png'
import { NavLink } from 'react-router-dom'
import toast from 'react-hot-toast';
import appstore from '../../assets/img/store/app-store.png'
import playstore from "../../assets/img/store/playstore theme.png"
import { APP_STORE_URL, PLAY_STORE_URL } from '../../config/config';
const Downloads = () => {
    return (
        <>
            <section className="sec05-1 sec-pb-0 ">
                <div className="container">
                    <div className="row banner column-reverse">
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img' src={mapp}></img>
                        </div>
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Downloads</h4>
                                <h2 className="w market_title outfit">Get the App Now </h2>
                                <p className="banner_text w outfit">Don't miss out on the opportunity to dive into the future of finance with Bitnetto! Trade swiftly on the go and access our platform anytime, anywhere. Live without limits." Join us today and experience the freedom and flexibility to trade whenever and wherever inspiration strikes.   </p>
                                <div class="store d-flex">
                                    <NavLink to={PLAY_STORE_URL}><img src={playstore} alt="Deposit-image" className='w-10' /></NavLink>
                                    <NavLink to={APP_STORE_URL} target="_blank" ><img src={appstore} alt="Deposit-image" className='w-10' /></NavLink>

                                    {/* <NavLink to={APK_URL} className="btn-1 sign-btn1">Download App</NavLink>
                                    <NavLink to={APK_URL} className="btn-1 sign-btn1">Download App</NavLink>
 */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Downloads