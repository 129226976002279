import React, { useEffect } from 'react'
import { saveAs } from 'file-saver';
import bannerimg from '../../assets/img/Homepage/home-banner.png'
import chart1 from '../../assets/icons/chart-green.png'
import chart2 from '../../assets/icons/chart-red.png'
import money from '../../assets/img/Homepage/money-bag.png'
import encryption from '../../assets/img/Homepage/encryption.png'
import time from '../../assets/img/Homepage/time-is-money.png'
import setup from '../../assets/img/Homepage/set-up.png'
import step1 from '../../assets/img/Homepage/step1.png'
import step2 from '../../assets/img/Homepage/step2.png'
import step3 from '../../assets/img/Homepage/step3.png'
import step4 from '../../assets/img/Homepage/step4.png'
import mapp from '../../assets/img/Homepage/mobile-app.png'
import appstore from '../../assets/img/store/app-store.png'
import playstore from "../../assets/img/store/playstore theme.png"
import toast from 'react-hot-toast';

import { NavLink, useNavigate } from 'react-router-dom'
import { APP_STORE_URL, PLAY_STORE_URL } from '../../config/config';
// import apkFile from '../../assets/File/bitnetto.apk'

const HamePage = () => {

    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    useEffect(() => {
        {
            !token && (
                navigate('/')
            )
        }
    }, [token])
    return (
        <>
            <section className="sec04">
                <div className="container">
                    <div className="row banner">
                        <div className="col-lg-6 col-md-6 align-self col_b_1">
                            <div className="col_1">
                                <h1 className="banner_heading w outfit">Discover Seamless Exchanges for Your Digital Coins
                                    <span className="sb">  Bitnetto  </span></h1>
                                <p className="banner_text w outfit">Experience top-notch trading with Bitnetto, the leading crypto exchange. Start trading with us today and take your investment journey to the next level!</p>
                                <p className=" banner_text sb outfit">Join the Crypto Revolution - Turn Your Dreams into Reality Today!</p>
                                {
                                    token ? (<NavLink to="/referral" className="banner_btn outfit ">Potential Rewards </NavLink>)
                                        : (<NavLink to="/signup" className="banner_btn outfit ">Join the Bitnetto </NavLink>)
                                }

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center ">
                            <div className='b-image'>
                                <img className='banner_img w-100' src={bannerimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec03">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 align-self">
                                    <div className="profile_box profile_box1  d-flex">
                                        <div className="profile_box_icon text-center">
                                            <img src={step1}></img>
                                        </div>
                                        <div>
                                            <h5 className="profile_box_title text-center outfit w">Step 1: Sign Up</h5>
                                            <p className="banner_text gr outfit text-center mb-0">Keep Your Wallet Safe and Join the Crypto Community Today!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 align-self">
                                    <div className="profile_box profile_box2 d-flex">
                                        <div className="profile_box_icon text-center">
                                            <img src={step2}></img>
                                        </div>
                                        <div>
                                            <h5 className="profile_box_title text-center outfit w">Step 2: Verify</h5>
                                            <p className="banner_text gr outfit text-center mb-0">
                                                Secure Your Wallet and Join Our Community Today!                               </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 align-self">
                                    <div className="profile_box profile_box3 d-flex">
                                        <div className="profile_box_icon text-center">
                                            <img src={step3}></img>
                                        </div>
                                        <div>
                                            <h5 className="profile_box_title text-center outfit w">Step 3: Fund </h5>
                                            <p className="banner_text gr outfit text-center mb-0"> We place a high priority on safeguarding your assets and funds.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 align-self">
                                    <div className="profile_box profile_box4 d-flex">
                                        <div className="profile_box_icon text-center">
                                            <img className='img-w-100' src={step4}></img>
                                        </div>
                                        <div>
                                            <h5 className="profile_box_title text-center outfit w">Step 4: Trade </h5>
                                            <p className="banner_text gr outfit text-center mb-0">
                                                Elevate Your Trading: Join Us Today to Reach Elite Status! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className='profile_box_col2'>
                                <h4 className='sb market_sub_title outfit'>Create Profile</h4>
                                <h2 className='w  market_title outfit'>Get Started Easily with Bitnetto</h2>
                                <p className='banner_text w outfit'>Discover the Future of Financial Security! Join our growing community today for special investment chances and lasting growth. Bitnetto makes it easy to understand and navigate the market, with a focus on innovation and safety. Invest with confidence for the future. Join Bitnetto now to improve your trading skills and find new chances to grow!"</p>
                                <div class="">

                                    <NavLink to="/trade" className="banner_btn outfit ">Start Trading </NavLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="sec04">
                <div className="container">
                    <h4 className="sb text-center market_sub_title outfit">Benefits</h4>
                    <h2 className="w text-center market_title outfit">
                        Explore Incredible Exchange Features!</h2>
                    <p className="banner_text w outfit text-center gr">
                        Don't settle for average; level up your crypto game one step at a time. You'll be boasting about us in no time!</p>
                    <div className="margin_top ">
                        <div className="row mt-5 ">
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-5">
                                <div className="icon_box-jaw icon_box-jaw1 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={setup}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Shield Your Investments</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Trust Our Secure Crypto Measures for Peaceful, Profitable Trading. Robust Asset Protection Guaranteed.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-5">
                                <div className="icon_box-jaw icon_box-jaw2 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={time}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Real-Time Crypto Trading</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Capture Opportunities: Swiftly Trade Cryptocurrencies on Our Platform, Where Time is of Essence.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-m-100 mb-5">
                                <div className="icon_box-jaw icon_box-jaw3 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={encryption}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Shielded Assets</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Trust in Our Crypto Exchange: Your Assets and Transactions Remain Secure, Safeguarded from Unwanted Access.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-m-100 mb-5">
                                <div className="icon_box-jaw icon_box-jaw4 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={money}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Profit Maximization</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Optimize Your Gains: Benefit from Our Competitive Transaction Fees, Reinvest in Your Crypto Portfolios</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="sec05 sec-pb-0">
                <div className="container">
                    <div className="row banner column-reverse">
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img' src={mapp}></img>
                        </div>
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Downloads</h4>
                                <h2 className="w market_title outfit">Get the App Now </h2>
                                <p className="banner_text w outfit">Don't miss out on the opportunity to dive into the future of finance with Bitnetto! Trade swiftly on the go and access our platform anytime, anywhere. Live without limits." Join us today and experience the freedom and flexibility to trade whenever and wherever inspiration strikes.   </p>
                                <div class="store d-flex">
                                    <NavLink to={PLAY_STORE_URL}><img src={playstore} alt="Deposit-image" className='w-10' /></NavLink>
                                    <NavLink to={APP_STORE_URL} target="_blank"><img src={appstore} alt="Deposit-image" className='w-10' /></NavLink>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="sec04">
                <div className='container'>
                    <h4 class="sb text-center market_sub_title outfit">Top Market</h4>
                    <h2 class="w text-center market_title outfit">Unleash your crypto potential</h2>
                    <div className='margin-top'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4'>
                                <div className='chart_box chart_box1'>
                                    <div className='d-flex'>
                                        <div className='mt-3 ms-3'>
                                            <span className="cardano w outfit me-2"> The Sandbox</span>
                                            <span className="sb ada_bg">ADA</span>
                                            <span className="g cardano ms-2"> + 0.49%
                                                <i class="fa-solid fa-chart-line ms-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <span className="cardano1 outfit w">20.7636 <span className="gr outfit ms-3"> USDT </span></span>
                                    <img src={chart1} className='chart1'></img>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4'>
                                <div className='chart_box chart_box2'>
                                    <div className='d-flex'>
                                        <div className='mt-3 ms-3'>
                                            <span className="cardano w outfit me-2"> Cardano coin</span>
                                            <span className="sb ada_bg">ADA</span>
                                            <span className="r cardano ms-2"> + 0.89%
                                                <i class="fa-solid fa-chart-line ms-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <span className="cardano1 outfit w">80.85636 <span className="gr outfit ms-2"> USDT </span></span>
                                    <img src={chart2} className='chart1'></img>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4'>
                                <div className='chart_box chart_box3'>
                                    <div className='d-flex'>
                                        <div className='mt-3 ms-3'>
                                            <span className="cardano w outfit me-2"> Chainlink</span>
                                            <span className="sb ada_bg">ADA</span>
                                            <span className="g cardano ms-2"> + 49%
                                                <i class="fa-solid fa-chart-line ms-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <span className="cardano1 outfit w">90.25836 <span className="gr outfit ms-2"> USDT </span></span>
                                    <img src={chart1} className='chart1'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HamePage