import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ProfileContext } from '../Profilecontextapi';

const PopUp = () => {

    const { userData, loading, getUserDetail } = useContext(ProfileContext);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    useEffect(() => {
        if (isLoggedIn()) {
            setShowWelcomeModal(true);
        }
    }, []);
    const isLoggedIn = () => {
        return true;
    }

    const handleClose = () => setShowWelcomeModal(false);

    return (
        <div>
            <Modal show={showWelcomeModal} onHide={handleClose} dialogClassName="custome-animate" >
                <Modal.Body className='popup-modal-body'>
                    <Button onClick={handleClose} className="close-button-popup">
                        &times;
                    </Button>

                    <div className='pop-header'>
                        <h3>Welcome To Dashboard</h3>
                    </div>
                    <div className='pop-inner-body'>
                        <p>Your KYC is pending.....</p>
                        <p>Hey there! It seems you've registered with your email address. Please take a moment to update your mobile number for enhanced account security. Alternatively, if you registered with your mobile number, kindly update your email address to ensure seamless communication. Thank you for your cooperation! </p>
                    </div>
                    <div className='popup-kyc-btn'>
                        {/* <NavLink className=" btn-1 login-btn1 popup-kyc-btn1 " to="/user-verify">Phone</NavLink> */}

                        {(userData?.phone_verified === false) ?
                            (
                                <NavLink to="/user-verify" className=" btn-1 login-btn1">Verify Your {'Phone '} No.</NavLink>

                            ) :
                            ''
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PopUp