import React from 'react'
import logo from '../assets/img/Logo/logo-white.png'
import logoBlack from '../assets/img/Logo/logo-black.png'
import { NavLink } from 'react-router-dom'
// import card1 from '../assets/img/card-1.svg'
// import card2 from '../assets/img/card-2.svg'
// import card3 from '../assets/img/card-3.svg'

import { FaFacebookSquare, FaInstagram, FaLinkedinIn, FaTelegramPlane, FaTwitter, FaYoutube } from "react-icons/fa";
import { CiMail } from 'react-icons/ci';

const UserFooter = ({ theme }) => {
    return (
        <>
            <section className='footer'>
                <div className='container'>
                    <div className='footer-row'>
                        <div className='footer-column1'>
                            <div className='footer-logo'>
                                <img src={theme === 'dark' ? logo : logoBlack} alt="logo-image" />
                            </div>
                            <p>The ultimate crypto exchange for you. Experience innovation, fast transactions, and top security</p>

                            <div className='social-icon'>
                                <NavLink to="https://www.instagram.com/bitnetto.io/"><FaInstagram /></NavLink>
                                <NavLink to="https://www.youtube.com/@bitnetto"><FaYoutube /></NavLink>
                                <NavLink to="https://t.me/+nrBsNrfuCf1mYmVl"><FaTelegramPlane /></NavLink>
                                <NavLink to="https://www.facebook.com/bitnetto.io"><FaFacebookSquare /></NavLink>
                                <NavLink to="mailto:support@bitnetto.io"><CiMail /></NavLink>
                            </div>
                        </div>
                        <div className='footer-column2'>
                            <div className='footer-heading'>
                                <h4>The Platform</h4>
                            </div>
                            <ul className='footer-ul'>
                                <li>
                                    <NavLink to="/market" className="nav-link">Markets</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/comming" className="nav-link">Fees</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/convert" className="nav-link">Convert</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/trade" className="nav-link">Exchange</NavLink>
                                </li> */}
                                <li>
                                    {/* <NavLink to="/convert" className="nav-link">Convert</NavLink> */}
                                </li>
                                {/* <li>
                                    <NavLink to="/comming" className="nav-link">Wallet</NavLink>
                                </li> */}
                                <li>
                                    {/* <NavLink to="/" className="nav-link">Markets</NavLink> */}
                                </li>
                            </ul>
                        </div>
                        <div className='footer-column2'>
                            <div className='footer-heading'>
                                <h4>Support</h4>
                            </div>
                            <ul className='footer-ul'>
                                <li>
                                    {/* <NavLink to="/comming" className="nav-link">Your Bit Matters</NavLink> */}
                                </li>
                                <li>
                                    <NavLink to="/support" className="nav-link">Contact Support</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faq" className="nav-link">FAQs</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/policy" className="nav-link">Privacy & Policy</NavLink>
                                </li>

                            </ul>
                        </div>
                        <div className='footer-column2'>
                            <div className='footer-heading'>
                                <h4>Resources</h4>
                            </div>
                            <ul className='footer-ul'>
                                {/* <li>
                                    <NavLink to="/download" className="nav-link">Downloads</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/comming" className="nav-link">Mobile Application</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/comming" className="nav-link">Bring Your Crew</NavLink>
                                </li>


                            </ul>
                        </div>
                        <div className='footer-column2'>
                            <div className='footer-heading'>
                                <h4>Learn</h4>
                            </div>
                            <ul className='footer-ul'>
                                <li>
                                    <NavLink to="/trade" className="nav-link">What's Trending</NavLink>
                                </li>
                                <li>
                                    {/* <NavLink to="/" className="nav-link">Wen’s new bits</NavLink> */}
                                </li>
                                <li>
                                    <NavLink to="/Comming" className="nav-link">Events</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Comming" className="nav-link">Inside Scoop</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/Comming" className="nav-link">The latest buzz</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='footer-column2'>
                            <div className='footer-heading'>
                                <h4>Company</h4>
                            </div>
                            <ul className='footer-ul'>
                                <li>
                                    <NavLink to="/About" className="nav-link">About Us</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/Comming" className="nav-link">Careers</NavLink>
                                </li> */}
                                <li>
                                    {/* <NavLink to="/support" className="nav-link">Supported companies</NavLink> */}
                                </li>
                                <li>
                                    <NavLink to="/security" className="nav-link">Security</NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>


                    <div className='footer-bottom'>
                        <div className='footer-left'>
                            <p>© 2024 Bitnetto. All Rights Reserved</p>
                        </div>
                        {/* <div className='footer-right'>
                        <p>
                            We accept following payment systems
                        </p>
                        <div className='card-icon'>
                            <span><img src={card1} alt="" /></span>
                            <span><img src={card2} alt="" /></span>
                            <span><img src={card3} alt="" /></span>
                            <span><img src={card1} alt="" /></span>
                        </div>

                    </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserFooter