import React, { useEffect, useState, useContext } from 'react'
import TradeTable from './TradeTable';
import TradingPage from './OrderBook'
import RecentTrades from './RecentTrades';
import TradeMid1 from './TradeMid1';
import BuySell from './BuySell';
import TradingViewWidget from './TradingViewWidget';

import { useLocation } from 'react-router-dom'
import TradeFooterTable from './TradeFooterTable';

// import comming from '../../assets/icons/comming-soon.jpg'
import comming from '../assets/icons/comming-soon.jpg'
import { MarketContext } from '../Marketcontextapi';
import CustomChart from './CustomChart';

const Trade = ({ theme }) => {
    const [value, setValue] = useState();
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const symbol = params.get('symbol');
    const [custom, setCustom] = useState();
    const { data, error, loading } = useContext(MarketContext);


    console.log('chart data->>',data);
 


    // const getCustom =async () => {
        
    //     const customTokens = await data.filter(token => token.tokenType === "CUSTOM");
    //     console.log('customTokens', customTokens);
    //     const customData =await customTokens.map((value) => {
    //         return (
    //             setCustom(value.tokenType)
    //         )
    //     });
    //     setCustom(customData)
    // }





    useEffect(() => {
        // getCustom();
    }, [])
    console.log('custom', custom);

    return (
        <>

            <section className='trade-main'>
                <div className='container-fluid'>
                    <div className='row mt-1 mb-1'>
                        {/* <div className='col-md-3'>
                            <div className='trade-column1 '>
                                <TradeTable symbol={symbol ? symbol : 'BNB'} />
                            </div>
                        </div> */}
                        <div className='col-md-6 p-1'>
                            <div className='trade-column2 '>
                                <TradeMid1 symbol={symbol ? symbol : 'BNB'} />

                                {symbol === "GLC" ? (<CustomChart symbol={symbol ? symbol : 'BNB'}/>) :
                                    (<TradingViewWidget symbol={symbol ? symbol : 'BNB'} theme={theme} />)}


                            </div>
                        </div>
                        <div className='col-md-6 '>
                            <div className='row'>
                                <div className='col-md-6 p-1'>
                                    <div className='trade-column3 '>
                                        <TradingPage symbol={symbol ? symbol : 'BNB'} />
                                    </div>
                                </div>
                                <div className='col-md-6 p-1'>
                                    <div className='trade-column3 '>

                                        <RecentTrades symbol={symbol ? symbol : 'BNB   '} />

                                    </div>
                                </div>
                                <div className='col-md-12 p-1'>
                                    <div className='trade-column2 ' >
                                        <BuySell symbol={symbol ? symbol : 'BNB'} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>








            <section className='mobile-trade-tab'>
                <div className='container-fluid p-0'>
                    <nav>
                        <div class="nav nav-tabs mb-3 mobile-trade-nav" id="nav-tab" role="tablist">
                            <button class="nav-link active mb-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Trade</button>
                            <button class="nav-link mb-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Buy Sell</button>
                            <button class="nav-link mb-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Order Book</button>
                        </div>
                    </nav>

                    <div class="tab-content tab-content-body p-3" id="nav-tabContent">
                        <div class="tab-pane active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className='trade-column2'>
                                <TradeMid1 symbol={symbol ? symbol : 'BNB'} />
                                <TradingViewWidget symbol={symbol ? symbol : 'BNB'} theme={theme} />

                            </div>
                        </div>
                        <div class="tab-pane " id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div className='trade-column2'>

                                <BuySell symbol={symbol ? symbol : 'BNB'} />

                            </div>
                        </div>
                        <div class="tab-pane " id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <div className='trade-column3 '>
                                <TradingPage symbol={symbol ? symbol : 'BNB'} />
                                <div className='mt-2'>
                                    <RecentTrades symbol={symbol ? symbol : 'BNB   '} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='trade-page-footer'>
                <TradeFooterTable />

            </div>

            {/* <div className='Container'>
                <div className='row'>
                    <div className='col-lg-12,col-sm-12'>
                        <div className='vi w-100'>

                            <img src={comming} alt='comming soon ' className='w-100' />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Trade


