import React, { useEffect, useState } from 'react'
import ProfileTopHeader from './ProfileTopHeader'
import ProfileSidebar from './ProfileSidebar'
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import PuffLoader from 'react-spinners/PuffLoader';
import dateFormat, { masks } from "dateformat";
import { GrFormPrevious, GrFormNext } from "react-icons/gr"


const BugReport = () => {
    const [pageUrl, setPageUrl] = useState();
    const [title, setTitle] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [bugHistory, setBugHistory] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageDescription, setPageDescription] = useState();

    const BugHandle = async () => {
        if (!pageUrl || !title || !pageDescription) {
            toast.dismiss();
            toast.error('All fields are required');
            return;
        }

        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/reportbug`, {
            method: "POST",
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                url: pageUrl,
                description: pageDescription,
            }),
        });
        const res = await response.json();
        console.log('response_bug_report', res);
        if (res.success === 1) {
            toast.success(res.message);
            setTitle('');
            setPageUrl('');
            setPageDescription('');
        } else {
            toast.error(res.message);
        }

    }


    const getHistory = async (skip = 0) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/user_reportBug_history`, {
            method: "Post",
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit: limit,
                skip: skip
            })
        });
        const res = await response.json();
        console.log('bugHistory', res);
        if (res.success === 1) {
            setBugHistory(res.data)
            setTotal(res.total);
        }

        // console.log(res.total)
    };
    useEffect(() => {
        getHistory();
    }, [])

    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        getHistory(skip);
    }, [currentPage, limit]);

    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <>
            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='ref-page-title'>
                                    <h3>Bug Reports</h3>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 '>
                                        <div className='bug-report-form'>
                                            <form>

                                                <div className='form-group '>
                                                    <label>Bug Title</label>
                                                    <input type="text" placeholder='Title ' value={title} onChange={(e) => setTitle(e.target.value)} className='form-control mt-2' />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <label>Bug Page URL</label>
                                                    <input type="text" placeholder='Page URL' value={pageUrl} onChange={(e) => setPageUrl(e.target.value)} className='form-control mt-2' />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <label>Write a short Description</label>
                                                    <textarea className='form-control ' value={pageDescription} onChange={(e) => setPageDescription(e.target.value)}></textarea>
                                                </div>
                                                <div className='form-group mt-4'>
                                                    <button type='button' onClick={BugHandle} className='btn login-btn'>Submit Report</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='bug-column-2'>
                                            <h5>Report bugs and earn up to 1000 crypto as a bug bonus!</h5>
                                            <p>If you encounter any bugs or errors on our platform, let us know, and we'll reward you generously with up to 1000 crypto as a bug bonus!
                                            </p>
                                            <p>Your feedback is invaluable in helping us enhance our platform's performance and user experience. Don't hesitate to report any issues you come across—your contribution not only helps us improve but also earns you crypto rewards.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className='ref-bonus-main'>
                                    <h3>Bug History</h3>
                                    <div className='ref-table-main '>
                                        <table class="table ref-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S No.</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">URL</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Date & Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bugHistory.length > 0 ? bugHistory?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td>{(currentPage - 1) * limit + index + 1}</td>
                                                                {/* <td>{index + 1}</td> */}
                                                                <td>{item.title}</td>
                                                                <td>{item.url}</td>
                                                                <td>{item.description}</td>
                                                                <td>{item.status}</td>
                                                                <td>{dateFormat(item.createdAt, "dd-mm-yyyy,hh:mm:ss TT ")}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                                    : (<tr className='text-center'>
                                                        <td colSpan='4'> <div className='loader-container'>
                                                            <PuffLoader color="#36d7b7" cssOverride={{ textAlign: 'center' }} size={30} />
                                                        </div> Data Not Found</td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>
                                        <div className='market-table-footer'>
                                            {bugHistory.length > 0 ? (
                                                <>
                                                    <div className='total-page'>
                                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                                    </div>
                                                    <div className='pagination-div'>
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination">
                                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                        <GrFormPrevious />
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item`}>
                                                                    <a className="page-link page-link1" >
                                                                        {currentPage}
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                                        <GrFormNext />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </>
                                            ) : (
                                                null
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BugReport