import React, { useState, useEffect } from 'react';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { base_url } from '../../config/config';
import dateFormat from 'dateformat';
import Skeleton from 'react-loading-skeleton';
const WithdrawList = () => {
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit] = useState(10) // Define limit here
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);


    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        fetchWithdrawalHistory(skip);
    }, [currentPage, limit]);



    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const fetchWithdrawalHistory = async () => {
        try {
            const token = localStorage.getItem("token");
            const skip = (currentPage - 1) * limit;
            setLoading(true)
            const response = await fetch(`${base_url}/withdrawHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                body: JSON.stringify({
                    limit,
                    skip
                })
            });

            const data = await response.json();
            console.log("total", data.total)
            if (data.success === 1) {
                setWithdrawalHistory(data.transaction);
                setTotal(data.total);
                setLoading(false)
            } else {
                console.error("Error: ", data.message);
                setLoading(false)
            }
        } catch (error) {
            console.error("Error fetching withdrawal history: ", error);
        }
    };

    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='w-table-heading mt-5'>
                        <div className='over-pagetitle-01'>
                            <h2>Withdrawal History</h2>
                        </div>
                    </div>
                    <div className='market-table'>
                        <table className="table mt-table">
                            <thead>
                                <tr>
                                    <th scope="col">S No.</th>
                                    <th scope="col">Token Name</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Wallet Address</th>
                                    <th scope="col">Network</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date & Time</th>
                                </tr>
                            </thead>


                            {loading ? (
                                <Skeleton count={5} height={50} baseColor="#202020" highlightColor="#444" />
                            ) : (
                                <tbody>
                                    {(withdrawalHistory.length > 0) ? (
                                        withdrawalHistory?.map((withdrawal, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{withdrawal.tokenName}</td>
                                                        <td>{withdrawal.amount}</td>
                                                        <td>{withdrawal.wallet_address}</td>
                                                        <td>{withdrawal.networkname}</td>
                                                        <td>{withdrawal.status}</td>
                                                        <td>{dateFormat(withdrawal.createdAt, "yyyy-mm-dd,hh:mm:ss TT")}</td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                        )) :
                                        (<tr className='text-center'>
                                            <td colSpan='10'>Data Not Found</td>
                                        </tr>)
                                    }
                                </tbody>
                            )}
                        </table>

                        {/* Pagination */}
                        <div className='market-table-footer'>
                            {withdrawalHistory.length > 0 ? (
                                <>
                                    <div className='total-page'>
                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                        <GrFormPrevious />
                                                    </a>
                                                </li>

                                                <li className={`page-item`}>
                                                    <a className="page-link page-link1" >
                                                        {currentPage}
                                                    </a>
                                                </li>

                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                        <GrFormNext />
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            ) : (
                                null
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawList;

