import React, { useEffect, useContext, useState } from 'react'
import token1 from '../assets/icons/bnb.png';
import { IoTrendingDownOutline, IoTrendingUpOutline } from 'react-icons/io5';
import { TbActivityHeartbeat, TbArrowsUpDown } from 'react-icons/tb';
import { CiCircleQuestion } from 'react-icons/ci';
import { MdOutlineAccessTime } from 'react-icons/md';
import jsonData from '../Data.json';
import { base_url } from '../config/config';
import { useLocation } from 'react-router-dom'
import { MarketContext } from '../Marketcontextapi';
import { IoMdArrowDropdown } from 'react-icons/io';
import TradeTable from './TradeTable';
const TradeMid1 = ({ symbol }) => {
    // console.log('symbol: ', symbol);
    const { data, error, loading } = useContext(MarketContext);
    // const [error, setError] = useState(null);
    // console.log('data-->tradepage', data);

    const filteredData = data.filter(item => item.symbol === symbol);


    // useEffect(()=>{

    // },[symbol,filteredData])
    const DropdownBtn = () => {
        let dbody = document.getElementById('dropdown-body');
        dbody.classList.toggle("add-dropdown-body")
    }

    return (
        <>

            <div className='card trade-mid-card'>

                {
                    filteredData?.map((item, index) => {
                        return (
                            <>
                                <div div className='t-mid-header'>
                                    <div className='t-mid-left'>
                                        <img src={item.image} alt='h-image' />
                                        <span className='uc-name'>{item.name}</span>
                                        <span className='uc-name-color'>{item.symbol}</span>
                                    </div>
                                    <div className='t-mid-right'>
                                        <span className={item.price_change_percentage_24h > 0 ? 'tm-right-value1' : 'tm-right-value1-red'}>{item.price ? ((item.price).toFixed(2)) : 0}</span>


                                        {
                                            item.price_change_percentage_24h > 0 ? (<span className='green-value'>{item.price_change_percentage_24h}% <IoTrendingUpOutline className='up-ic' /></span>)
                                                :
                                                (<span className='red-value'>{item.price_change_percentage_24h}%<IoTrendingDownOutline className='down-ic' /></span>)
                                        }


                                        {/* <span className='h-text'>{item.name} ({item.current_price})</span> */}
                                        {/* <div className='markit-table-dropdown'>
                                            <button type='button' className='btn mt-dropdown' onClick={DropdownBtn}>{symbol}<span><IoMdArrowDropdown className='ms-4' /></span></button>

                                            <div id='dropdown-body' className='mt-dropdown-body '>
                                                <TradeTable symbol={symbol ? symbol : 'BNB'} />
                                            </div>
                                        </div> */}

                                        <div class="dropdown">
                                            <a class="btn mt-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                               {symbol}
                                            </a>

                                            <ul class="dropdown-menu mt-dropdown-body" aria-labelledby="dropdownMenuLink">
                                                {/* <li><a class="dropdown-item" href="#">Action</a></li> */}
                                                <TradeTable symbol={symbol ? symbol : 'BNB'} />
                                            </ul>
                                        </div>


                                    </div>


                                </div>





                                {/* <div className='card-mid-content'> */}
                                {/* <div className='card-inner-box1'>
                                        <div className='box1'>
                                            <p><TbActivityHeartbeat className='heartbeet-icon' /><span className='ms-1'> 24 changes</span></p>
                                            <div className='box1-footer'>
                                                <div><span>0.00</span></div>
                                                <div className='box-tooltip'>
                                                    <CiCircleQuestion className='ques-icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='box1'>
                                            <p><MdOutlineAccessTime className='heartbeet-icon' /><span className='ms-1'>24-vol({item.name})</span></p>
                                            <div className='box1-footer'>
                                                <div><span>0.00</span></div>
                                                <div className='box-tooltip'>
                                                    <CiCircleQuestion className='ques-icon' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='box1'>
                                            <p><MdOutlineAccessTime className='heartbeet-icon' /><span className='ms-1'> 24 changes({item.symbol})</span></p>
                                            <div className='box1-footer'>
                                                <div><span>0.00</span></div>
                                                <div className='box-tooltip'>
                                                    <CiCircleQuestion className='ques-icon' />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                {/* <div className='range-div'>
                                        <div className='range-header'>
                                            <span><TbArrowsUpDown className='up-down-icon' /></span>
                                            <p>High/Low Price<span> <CiCircleQuestion className='ques-icon' /></span></p>
                                        </div>
                                        <div className='mt-2'>
                                            <label for="vol"></label>
                                            <input type="range" id="vol" name="vol" min="0" max="50" className='w-100' />
                                        </div>
                                        <div className='range-footer'>
                                            <p><span>Low :</span>0.00</p>
                                            <p><span>High :</span>0.00</p>
                                        </div>
                                    </div> */}
                                {/* </div> */}


                            </>
                        )
                    })
                }
            </div >

        </>
    )
}

export default TradeMid1


