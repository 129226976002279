import React, { useEffect, useState } from 'react'
import c1 from '../../assets/icons/m1-icon.png';
import m1 from '../../assets/icons/m1-icon.png';
import m2 from '../../assets/icons/bnb.png';
import m3 from '../../assets/icons/btc.png';
import m4 from '../../assets/icons/m2-icon.png';
import { MdOutlineSearch } from 'react-icons/md';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import OverHeader from './OverHeader';
import { base_url } from '../../config/config';
import Skeleton from 'react-loading-skeleton';
import dateFormat from 'dateformat';

const TradingAccount = () => {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [hideZero, setHideZero] = useState(false);
    const [trade, setTrade] = useState();
    const [fundData, setFundData] = useState([]);

    const getData = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/userwallet`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                wallet_type: "trading_wallet"
            })
        });
        const data = await response.json();
        if (data.success === 1) {
            setTrade(data.wallets.trading_wallet.balance);
            setFundData(data.wallets.trading_wallet.wallets)
            setLoading(false);
        } else {
            console.error('Error:', response.status);
        }
    };
    useEffect(() => {
        getData()

    }, [])
    return (
        <>
            <OverHeader />
            <section>
                <div className='container'>
                    <div className='overview-tab-content'>
                        <div className='over-content-01'>
                            <div className='over-01-left'>
                                <div className='over-pagetitle-01'>
                                    <h2>Trading Account History</h2>
                                </div>
                                <div className='d-gap d-flex align-items-center'>
                                    <div className='img-price-01 d-flex align-items-center'>
                                        <img src={c1} alt='coin-image' />
                                        <span>{((trade))} USDT</span>
                                    </div>

                                </div>
                            </div>
                            <div className='over-01-right'>

                                <div className=''></div>
                                <div className='over-01-innerdiv'>
                                    {/* <div className='form-group form-group-flex'>
                                        <input id="bls" type="checkbox" onChange={(e) => setHideZero(e.target.checked)} />
                                        <label htmlFor='bls' className='ms-2'>Hide Zero Balances</label>
                                    </div> */}
                                    <div className='form-group'>
                                        <div className='slider-top-srh'>
                                            <input type="search" placeholder='Search Coin Name' onChange={(e) => setSearch(e.target.value)} />
                                            <MdOutlineSearch className='srh-icon' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='market-table'>
                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Token Name</th>
                                            <th scope="col">USD Amount</th>
                                            <th scope="col">USD Price</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    {/* </table>
                                {loading ? (
                                    <Skeleton count={1
                                    } height={50} baseColor="#202020" highlightColor="#444" />
                                ) : (
                                    <table className="table mt-table"> */}
                                    <tbody>
                                        {fundData.filter((item) => {
                                            return (search.toUpperCase() === ''
                                                || item.tokenname.toUpperCase().includes(search.toUpperCase())) && (!hideZero || item.balance > 0);

                                        })
                                            .map((item, index) => {
                                                console.log('itemmm---', item);
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className='td-1'>
                                                            <div className='markit-td1'>
                                                                <span className='coin-name'>{item ? item.tokenname : ''}</span>
                                                            </div>
                                                        </td>
                                                        <td>{item ? item.usd_amount : ''}</td>
                                                        <td>{item ? item.usd_price : ''}</td>
                                                        <td>{item ? item.balance : ''}</td>
                                                        <td>{dateFormat(item.createdAt, "dd-mm-yy,hh:mm:ss TT")}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TradingAccount