import React from 'react'
// import logo2 from '../../assets/img/logo2.svg'
import '../../assets/css/About.css'
import target from '../../assets/img/target.png'
// import scope from '../../assets/img/scope.png'
// import aboutimg from '../../assets/img/about_img1.png'
import hello from '../../assets/img/about_img1.png'
import aboutsec02 from '../../assets/img/about_sec02.png'
import aboutsec03 from '../../assets/img/about_sec_03.png'
const About = () => {
    return (
        <>
            <section className='about01'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 text-center'>
                            <img className='about_banner_img' src={hello} alt='' />
                        </div>
                        <div className='col-lg-6 align-self'>
                            <h1 className='w outfit about-heading'>About Us</h1>
                            <p className='banner_text w outfit'>A new crypto exchange in the market is set to disrupt the industry. Bitnetto may not be a familiar name to you yet, but we are no novices in this field. We emerged after a major transformation to create an exchange everyone could use, understand, and thrive on</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about02'>
                <div className='container'>
                    <div className='about_sec_02'>
                        <div className='row column-reverse'>

                            <div className='col-lg-6 align-self'>
                                <h2 className='w outfit about-title'>Our Vision </h2>
                                <p className='banner_text w outfit'>Our vision has attracted many talented individuals to join us, and we have built an exchange ready to challenge the existing norms and achieve greatness.</p>
                                <p className='banner_text w outfit'>So, if you are ready to join this exciting journey, come aboard Bitnetto and let's create some remarkable bits together.</p>
                            </div>
                            <div className='col-lg-6 align-self text-center'>
                                <img className='about_sec_img2' src={aboutsec03} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about03'>
                <div className='container'>
                    <div className='about_sec_02'>
                        <div className='row'>
                            <div className='col-lg-6 align-self text-center'>
                                <img className='about_sec_img1' src={aboutsec02} alt='' />
                            </div>
                            <div className='col-lg-6 align-self'>
                                <h2 className='w outfit about-title'>Our Mission</h2>
                                <p className='banner_text w outfit'>We get it - the world of crypto can be a bit daunting. But here at Bitnetto, we believe everything is made out of bits - and we can only make a whole.</p>
                                <p className='banner_text w outfit'> That's why we consulted with crypto experts, and IT wizards to build an easy-to-use, rock-solid secure exchange</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About
