
import React, { useEffect, useRef } from 'react';

const TradingViewWidget = ({ symbol , theme }) => {

    const uniqueID = useRef(`tradingview-${Math.random().toString(36).substring(2, 15)}`);
    useEffect(() => {
        LoadChart();
    }, [symbol]);


    console.log('symbol-------------------',symbol);
    
    // console.log('cthattheme-------------------',theme );
    function LoadChart() {
        new window.TradingView.widget({
            "width": '100%',
            "height": '488px',
            "symbol": `BNB`,
            "interval": "D",
         "timezone": "Etc/UTC",
         "theme": `${theme}`,
         "style": "",
         "locale": "en",
         "toolbar_bg": "#f1f3f6",
         "enable_publishing": false,
         "allow_symbol_change": true,
         "container_id": "tvchart",
         "container_id": uniqueID.current,

        });
    }

    // console.log('symbol' ,);
    return (
        <>
            <div className='trading-view-chart'>
                <div className="tradingview-widget-container" id={uniqueID.current}></div>
                <select onChange={LoadChart} id="pairs" style={{ display: 'none' }}>
                    <option value={symbol}>{symbol}</option>
                </select>
            </div>
        </>
    );
};

export default TradingViewWidget;