import React, { useEffect, useState } from 'react'
import Select from 'react-select';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { LuArrowUpDown } from "react-icons/lu";
import toast from 'react-hot-toast';

import { Link, NavLink } from 'react-router-dom'
import { FaEye, FaLongArrowAltUp } from "react-icons/fa";
import c1 from '../../assets/icons/m1-icon.png';
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineSearch } from "react-icons/md";

import m1 from '../../assets/icons/m1-icon.png';
import m2 from '../../assets/icons/bnb.png';
import m3 from '../../assets/icons/btc.png';
import m4 from '../../assets/icons/m2-icon.png';


import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import OverHeader from './OverHeader';
import { base_url } from '../../config/config';
import _fetch from '../../config/api';
import TransferModal from './TransferModal';




const options = [
    { value: 'BTC', label: 'BTC', image: m1 },
    { value: 'USDT', label: 'USDT', image: m2 },
    { value: 'BNB', label: 'BNB', image: m3 },
];


const Overview = () => {

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#000" : null,
                color: "#333333",
                fontSize: '13px'
            };
        }
    };
    useEffect(() => {
        getData()
    }, [])



    const [selectedOption, setSelectedOption] = useState(null);
    const [Option, setOption] = useState(null);
    const [modelOption, setModelOption] = useState(null);

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    const [funding, setFunding] = useState();
    const [main, setMain] = useState();
    const [trade, setTrade] = useState();

    const [gData, setGData] = useState();
    const getData = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/userwallet`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                wallet_type: "main_wallet"
            })
        });


        const data = await response.json();
        if (data.success === 1) {
            setGData({
                main_balance: data.wallets.main_wallet.balance,
                funding_balance: data.wallets.funding_wallet.balance,
                trading_balance: data.wallets.trading_wallet.balance
            })
        }

    };








    return (
        <>
            <OverHeader />
            <section className='overview-header'>


                <div className='container'>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className='overview-tab-content'>
                                <div className='over-content-01'>
                                    <div className='over-01-left'>
                                        <div className='over-pagetitle-01'>
                                            <h2>Assets Overview</h2><span><FaEye className='over-01-icon' /></span>
                                        </div>
                                        <div className='d-gap d-flex align-items-center'>
                                            <div className='img-price-01 d-flex align-items-center'>
                                                <img src={c1} alt='coin-image' />
                                                <span>{gData ? ((gData.main_balance + gData.funding_balance + gData.trading_balance).toFixed(2)) : 0} USDT</span>
                                                {/* <span>0 USDT</span> */}
                                            </div>
                                            <div className='select-div-01'>
                                                {/* <Select
                                                    defaultValue={selectedOption}
                                                    onChange={setOption}
                                                    options={options}
                                                    styles={colourStyles}
                                                    formatOptionLabel={option => (
                                                        <div className="cselect-option">
                                                            <img src={option.image} alt="country" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                            {option.label}
                                                        </div>
                                                    )}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='over-01-right'>
                                        <div className='header-btn-main '>
                                            <NavLink className=" btn-1 login-btn1" to="/deposit">Deposit</NavLink>
                                            <NavLink className=" btn-1 login-btn1" to="/withdraw">Withdrawal</NavLink>
                                            <NavLink className=" btn-1 sign-btn1" onClick={onOpenModal} >Transfer</NavLink>

                                        </div>
                                    </div>
                                </div>

                                <div className='over-content-sec02'>
                                    {/* <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={6}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            320: {
                                                // width: 576,
                                                slidesPerView: 1,
                                            },
                                            479: {
                                                // width: 576,
                                                slidesPerView: 1,
                                            },
                                            576: {
                                                // width: 576,
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                // width: 768,
                                                slidesPerView: 3,
                                            },
                                            992: {
                                                // width: 768,
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                // width: 768,
                                                slidesPerView: 4,
                                            },
                                        }}
                                    // onSlideChange={() => console.log("slide change")}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card swiper-card'>
                                                <div className='d-flex s-card-header'>
                                                    <h5>BTC/<span>USDT</span></h5>
                                                    <p className='red-text'>-1.5%</p>
                                                </div>
                                                <h4 className=''>0.37</h4>
                                                <h6>$19,173.21</h6>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper> */}
                                </div>
                                <div className='row over-content-sec03'>
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={30}
                                        slidesPerView={6}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            320: {
                                                // width: 576,
                                                slidesPerView: 1,
                                            },
                                            479: {
                                                // width: 576,
                                                slidesPerView: 2,
                                            },
                                            576: {
                                                // width: 576,
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                // width: 768,
                                                slidesPerView: 2,
                                            },
                                            992: {
                                                // width: 768,
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                // width: 768,
                                                slidesPerView: 3,
                                            },
                                        }}
                                    // onSlideChange={() => console.log("slide change")}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='card card-2'>
                                                <NavLink to="/mainaccount" >
                                                    <li>
                                                        <NavLink to="/mainaccount">Main Account<span><IoIosArrowForward className='right-icon' /></span></NavLink>
                                                        <img src={c1} alt="img" />

                                                    </li>
                                                    <h5>{gData ? ((gData.main_balance).toFixed(2)) : 0} USDT</h5></NavLink>
                                                {/* <button className='btn' onClick={onOpenModal}>Transfer </button> */}
                                                {/* <span>Transfer</span> */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card card-2'>
                                                <NavLink to="/tradingaccount" >
                                                    <li>
                                                        <NavLink to="/tradingaccount">Trading Account<span><IoIosArrowForward className='right-icon' /></span></NavLink>
                                                        <img src={c1} alt="img" />

                                                    </li>
                                                    <h5>{gData ? ((gData.trading_balance).toFixed(2)) : 0} USDT</h5></NavLink>
                                                {/* <button className='btn' onClick={onOpenModal}>Transfer </button> */}
                                                {/* <span>Transfer</span> */}
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='card card-2'>
                                                <NavLink to="/fundingaccount" >
                                                    <li>
                                                        <NavLink to="/fundingaccount">Funding Account<span><IoIosArrowForward className='right-icon' /></span></NavLink>
                                                        <img src={c1} alt="img" />

                                                    </li>
                                                    <h5>{gData ? ((gData.funding_balance).toFixed(2)) : 0} USDT</h5></NavLink>
                                                {/* <button className='btn' onClick={onOpenModal}>Transfer </button> */}
                                                {/* <span>Transfer</span> */}
                                            </div>
                                        </SwiperSlide>




                                    </Swiper>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <TransferModal
                open={open}
                onClose={onCloseModal}
                onCloseModal={onCloseModal}
            />


        </>
    )
}

export default Overview