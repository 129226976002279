import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { base_url } from '../../config/config';
import { RiArrowUpDownLine } from "react-icons/ri";
import toast from 'react-hot-toast';
import dateFormat, { masks } from "dateformat";
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import swap from '../../assets/img/swap.png'

const Swap = () => {
    const [optionToken, setOptionToken] = useState([]);
    const [quantity, setQuantity] = useState('');
    const [sellQuantity, setSellQuantity] = useState('');
    const [fromToken, setFromToken] = useState(null);
    const [toToken, setToToken] = useState(null);
    const [manualUpdate, setManualUpdate] = useState(false);
    const [swapTransaction, setSwapTransaction] = useState([]);
    const [showBal, setShowBal] = useState(0);
    const token = localStorage.getItem("token");


    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [page] = useState(1)


    useEffect(() => {
        setSkip((currentPage - 1) * limit);
    }, [currentPage, limit]);

    const totalPages = Math.ceil(total / limit);
    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handlePageClick = (e, page) => {
        e.preventDefault();
        setCurrentPage(page);
    };



    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#0000003b" : null,
                color: "#333333",
                fontSize: '13px'
            };
        }
    };

    useEffect(() => {
        getAllToken();
        swapHistory();
        SwapShowBalance();


    }, [currentPage, limit, skip]);

    const SwapShowBalance = async (lebel) => {
        // console.log('lebel===>', lebel);
        try {
            let responseApi = await fetch(`${base_url}/userwalletbalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({
                    tokenName: lebel,
                    wallet_type: 'main_wallet'
                })
            });

            const responseData = await responseApi.json();
            // console.log('swap-balance-history', responseData);
            if (responseData.success === 1) {
                setShowBal(responseData?.token?.balance)
            } else {
                console.error('Failed to fetch user balance:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    const swapHistory = async () => {
        try {
            let responseApi = await fetch(`${base_url}/swap_transactions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({
                    limit: limit,
                    skip: skip
                })
            });

            const responseData = await responseApi.json();
            console.log('swap-history---111', responseData);
            if (responseData.success === 1) {
                setSwapTransaction(responseData.records)
                setTotal(responseData.total)

            } else {
                console.error('Failed to fetch user balance:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };
    const getAllToken = async () => {
        try {
            let response = await fetch(`${base_url}/swap/tokens`);
            const data = await response.json();
            if (data.success === 1) {
                const options = data.swapTokens.map(item => {
                    const priceData = data.tokenPrices.find(price => price.coinName === item.coinName);
                    return {
                        value: item._id,
                        label: item.coinName,
                        image: item.image,
                        price: priceData ? priceData.price : null
                    };
                });
                setOptionToken(options);
            }
        } catch (error) {
            console.error("Error fetching tokens: ", error);
        }
    }

    const handleFromTokenChange = (selectedOption) => {
        setFromToken(selectedOption);
        // console.log('handleFromTokenChange', selectedOption);
        SwapShowBalance(selectedOption.label);
    }

    const handleToTokenChange = (selectedOption) => {
        setToToken(selectedOption);
    }

    const handleQuantityChange = (event) => {
        const updatedValue = event.target.value;
        setQuantity(updatedValue);
        if (fromToken && toToken && updatedValue && fromToken.price && toToken.price) {
            const newSellQuantity = (parseFloat(updatedValue) * fromToken.price) / toToken.price;
            setSellQuantity(newSellQuantity.toFixed(10));
        }
    }

    const handleSellQuantityChange = (event) => {
        const updatedValue = event.target.value;
        setSellQuantity(updatedValue);
        if (fromToken && toToken && updatedValue && fromToken.price && toToken.price) {
            const newQuantity = (parseFloat(updatedValue) * toToken.price) / fromToken.price;
            setQuantity(newQuantity.toFixed(10));
        }
    }

    const handleSwap = () => {
        const temp = fromToken;
        setFromToken(toToken);
        setToToken(temp);
        setQuantity(sellQuantity);
        setSellQuantity(quantity);
    }
    const handleSwaps = async () => {
        if (fromToken && toToken && quantity) {
            try {
                const response = await fetch(`${base_url}/swap_coins`, {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        from: fromToken.label,
                        to: toToken.label,
                        quantity: parseFloat(quantity)
                    })
                });
                const data = await response.json();
                if (data.success === 1) {
                    swapHistory();
                    SwapShowBalance();
                    toast.dismiss();
                    toast.success(data.message);
                    setQuantity('');
                    setSellQuantity('')
                }
                else {
                    toast.dismiss();
                    toast.error(data?.message);
                }
                // console.log(data);
            } catch (error) {
                console.error("Error swapping coins: ", error);
            }
        } else {
            toast.dismiss();
            toast.error('All fields are required');
        }
    }

    return (
        <section className='swap-main'>
            <div className='container'>
                <div className='row swap-inner-row'>
                    <div className='col-md-6'>
                        <div className='swap-inner-col'>
                            <div className='swap-inner-header'>
                                <h3>Swap</h3>
                                <p>Available Token Balance: {showBal ? showBal.toFixed(2) : 0}</p>
                            </div>
                            <div className='swap-form'>
                                <form>
                                    <div className='main-input-flex'>
                                        <div className='form-group'>
                                            <label>You Pay</label>
                                            <input type="text" placeholder='0.00' value={quantity} onChange={handleQuantityChange} className='form-control mt-1' />
                                        </div>
                                        <div className='input-image'>
                                            <Select
                                                value={fromToken}
                                                onChange={handleFromTokenChange}
                                                options={optionToken}
                                                className="swap-select"
                                                styles={colourStyles}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <RiArrowUpDownLine className='awap' onClick={handleSwap} />
                                    </div>
                                    <div className='main-input-flex mt-4'>
                                        <div className='form-group'>
                                            <label>You Get</label>
                                            <input type="text" placeholder='0.00' value={sellQuantity} onChange={handleSellQuantityChange} className='form-control mt-1' />
                                        </div>
                                        <div className='input-image'>
                                            <Select
                                                value={toToken}
                                                onChange={handleToTokenChange}
                                                options={optionToken}
                                                className="swap-select"
                                                styles={colourStyles}

                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <button type="button" className="btn-1 login-btn1 w-100 swap-sell-btn" onClick={handleSwaps} >Swap</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='staking-image text-center'>
                            <img src={swap} alt='' className='w-75' />
                        </div>
                    </div>


                    <div className='col-md-12'>
                        <div className='w-table-heading mt-5'>
                            <div className='over-pagetitle-01'>
                                <h2>Swap History</h2>
                            </div>
                        </div>
                        <div className='market-table'>
                            <table className="table mt-table">
                                <thead>
                                    <tr>
                                        <th scope="col">S No.</th>
                                        <th scope="col">Buy Amount</th>
                                        <th scope="col">Sell Amount</th>
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>

                                        <th scope="col">Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(swapTransaction.length > 0) ? (
                                        swapTransaction?.map((item, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{(item.currency_amount) ? (item.currency_amount).toFixed(2) : 0}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.currency}</td>
                                                        <td>{item.swaptoken}</td>
                                                        <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                        )) :
                                        (<tr className='text-center'>
                                            <td colSpan='10'>Data Not Found</td>
                                        </tr>)
                                    }
                                    {/* {swapTransaction?.map((item, index) => {
                                        return (<>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.swaptoken}</td>

                                                <td>{(item.currency_amount) ? (item.currency_amount).toFixed(2) : 0}</td>
                                                <td>{item.amount}</td>
                                                <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                            </tr>
                                        </>)
                                    })} */}
                                </tbody>
                            </table>

                            <div className='market-table-footer'>
                                {swapTransaction.length > 0 ? (
                                    <>
                                        <div className='total-page'>
                                            <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                        </div>
                                        <div className='pagination-div'>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                            <GrFormPrevious />
                                                        </a>
                                                    </li>

                                                    <li className={`page-item`}>
                                                        <a className="page-link page-link1" >
                                                            {currentPage}
                                                        </a>
                                                    </li>

                                                    <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                        <a className="page-link page-link1" onClick={handleNextPage}>
                                                            <GrFormNext />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </>
                                ) : (
                                    null
                                )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default Swap;

