
// import React, { createContext, useState, useEffect, useMemo } from 'react';
// import { base_url } from './config/config';
// import { useNavigate } from 'react-router-dom';

// const ProfileContext = createContext();

// const ProfileProvider = ({ children }) => {
//     const [userData, setUserData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const token = localStorage.getItem("token");
//     const navigate = useNavigate();

//     const checkToken = () => {
//         if (!token) {
//             localStorage.clear('token')
//             navigate('/');
//         }
//     };

//     const getUserDetail = async () => {
//         try {
//             setLoading(true);
//             const response = await fetch(`${base_url}/v1/userProfile`, {
//                 method: 'get',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 }
//             });
//             const data = await response.json();
//             if (data.success === 1) {
//                 setUserData(data?.user);
//                 setLoading(false);
//             } else {
//                 localStorage.clear();
//                 setLoading(false);
//             }
//         } catch (err) {

//             setLoading(false);
//         }
//     };

//     const handleLogout = () => {
//         localStorage.removeItem('token');
//         setUserData(null);
//     };

//     useEffect(() => {
//         if(token){
//             getUserDetail();
//         }
//         checkToken();
//         getUserDetail()
//     }, [token]);
  
//     // console.log('userData--->',userData);
//     const contextValue = useMemo(() => ({ userData, loading, error, handleLogout,getUserDetail  }), [userData, loading, error]);
    
//     return (
//         <ProfileContext.Provider value={contextValue}>
//             {children}
//         </ProfileContext.Provider>
//     );
// };

// export { ProfileContext, ProfileProvider };


import React, { createContext, useState, useEffect, useMemo } from 'react';
import { base_url } from './config/config';
import { useNavigate } from 'react-router-dom';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    const getUserDetail = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${base_url}/v1/userProfile`, {
                method: 'get',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data.success === 1) {
                setUserData(data?.user);
                setLoading(false);
            } else {
                localStorage.clear();
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setUserData(null);
    };

    useEffect(() => {
        if (token) {
            getUserDetail();
        }
        // checkToken();
    }, [token]); 

    const contextValue = useMemo(() => ({ userData, loading, error, handleLogout,getUserDetail  }), [userData, loading, error]);

    return (
        <ProfileContext.Provider value={contextValue}>
            {children}
        </ProfileContext.Provider>
    );
};

export { ProfileContext, ProfileProvider };