// import React, { useState, useEffect, useRef } from 'react';
// import CanvasJSReact from '@canvasjs/react-charts';
// const CanvasJS = CanvasJSReact.CanvasJS;
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;


// const CustomChart = () => {

//     const [dataPoints, setDataPoints] = useState([]);
//     const chartRef = useRef(null);

//     useEffect(() => {
//         fetch('https://canvasjs.com/data/gallery/react/microsoft-stock-price.json')
//             .then((response) => response.json())
//             .then((data) => {
//                 const points = data.map((item) => ({
//                     x: item.x,
//                     y: item.y,
//                 }));
//                 setDataPoints(points);
//             });
//     }, []); // Empty dependency array to ensure this runs once on component mount

//     const options = {
//         exportEnabled: false,
//         title: {
//             text: 'Your Custom Coin',
//         },
//         axisX: {
//             valueFormatString: 'D MMM',
//         },
//         axisY: {
//             title: 'Price',
//             prefix: '$',
//         },
//         data: [
//             {
//                 type: 'candlestick',
//                 name: 'Trading',
//                 showInLegend: true,
//                 yValueFormatString: '$##0.00',
//                 xValueType: 'dateTime',
//                 dataPoints: dataPoints,
//             },
//         ],
//     };
//     return (
//         <>
//             <div className='container'>
//             <CanvasJSChart
//                 options={options}
//                 onRef={(ref) => (chartRef.current = ref)} // Use ref to keep reference to the chart
//             />
//             </div>
//         </>
//     );
// };

// export default CustomChart;


// import React, { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const CustomChart = () => {
//   const [series, setSeries] = useState([
//     {
//       data: [
//         {
//           x: new Date(1538778600000), // First data point
//           y: [6629.81, 6650.5, 6623.04, 6633.33], // [open, high, low, close]
//         },
//         {
//           x: new Date(1538780400000),
//           y: [6632.01, 6643.59, 6620, 6630.11],
//         },
//         {
//           x: new Date(1538782200000),
//           y: [6630.71, 6648.95, 6623.34, 6635.65],
//         },
//         {
//           x: new Date(1538784000000),
//           y: [6635.65, 6651, 6629.67, 6638.24],
//         },
//         {
//           x: new Date(1538785800000),
//           y: [6638.24, 6640, 6620, 6624.47],
//         },
//         {
//           x: new Date(1538787600000),
//           y: [6624.53, 6636.03, 6621.68, 6624.31],
//         },
//         {
//           x: new Date(1538789400000),
//           y: [6624.61, 6632.2, 6617, 6626.02],
//         },
//         {
//           x: new Date(1538791200000),
//           y: [6627, 6627.62, 6584.22, 6603.02],
//         },
//         {
//           x: new Date(1538793000000),
//           y: [6605, 6608.03, 6598.95, 6604.01],
//         },
//         {
//           x: new Date(1538794800000),
//           y: [6604.5, 6614.4, 6602.26, 6608.02],
//         },
//         {
//           x: new Date(1538796600000),
//           y: [6608.02, 6610.68, 6601.99, 6608.91],
//         },
//         {
//           x: new Date(1538798400000),
//           y: [6608.91, 6618.99, 6608.01, 6612],
//         },
//         {
//           x: new Date(1538800200000),
//           y: [6612, 6615.13, 6605.09, 6612],
//         },
//         {
//           x: new Date(1538802000000),
//           y: [6612, 6624.12, 6608.43, 6622.95],
//         },
//         {
//           x: new Date(1538803800000),
//           y: [6623.91, 6623.91, 6615, 6615.67],
//         },
//       ],
//     },
//   ]);

//   const [options, setOptions] = useState({
//     chart: {
//       type: 'candlestick',
//       height: 350,
//     },
//     title: {
//       text: 'CandleStick Chart',
//       align: 'left',
//     },
//     xaxis: {
//       type: 'datetime', // Ensure x-axis is formatted as datetime
//     },
//     yaxis: {
//       tooltip: {
//         enabled: true,
//       },
//     },
//   });

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart options={options} series={series} type="candlestick" height={350} />
//       </div>
//     </div>
//   );
// };
// export default CustomChart;


// import React, { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const CustomChart = () => {
//   const [series, setSeries] = useState([
//     {
//       data: [
//         {
//           x: new Date(1538778600000), // First data point
//           y: [6629.81, 6650.5, 6623.04, 6633.33], // [open, high, low, close]
//         },
//         {
//           x: new Date(1538780400000),
//           y: [6632.01, 6643.59, 6620, 6630.11],
//         },
//         {
//           x: new Date(1538782200000),
//           y: [6630.71, 6648.95, 6623.34, 6635.65],
//         },
//         {
//           x: new Date(1538784000000),
//           y: [6635.65, 6651, 6629.67, 6638.24],
//         },
//         {
//           x: new Date(1538785800000),
//           y: [6638.24, 6640, 6620, 6624.47],
//         },
//         {
//           x: new Date(1538787600000),
//           y: [6624.53, 6636.03, 6621.68, 6624.31],
//         },
//         {
//           x: new Date(1538789400000),
//           y: [6624.61, 6632.2, 6617, 6626.02],
//         },
//         {
//           x: new Date(1538791200000),
//           y: [6627, 6627.62, 6584.22, 6603.02],
//         },
//         {
//           x: new Date(1538793000000),
//           y: [6605, 6608.03, 6598.95, 6604.01],
//         },
//         {
//           x: new Date(1538794800000),
//           y: [6604.5, 6614.4, 6602.26, 6608.02],
//         },
//         {
//           x: new Date(1538796600000),
//           y: [6608.02, 6610.68, 6601.99, 6608.91],
//         },
//         {
//           x: new Date(1538798400000),
//           y: [6608.91, 6618.99, 6608.01, 6612],
//         },
//         {
//           x: new Date(1538800200000),
//           y: [6612, 6615.13, 6605.09, 6612],
//         },
//         {
//           x: new Date(1538802000000),
//           y: [6612, 6624.12, 6608.43, 6622.95],
//         },
//         {
//           x: new Date(1538803800000),
//           y: [6623.91, 6623.91, 6615, 6615.67],
//         },
//       ],
//     },
//   ]);

//   const [options, setOptions] = useState({
//     chart: {
//       type: 'candlestick',
//       height: 350,
//     },
//     title: {
//       text: 'CandleStick Chart',
//       align: 'left',
//     },
//     xaxis: {
//       type: 'datetime', // Ensure x-axis is formatted as datetime
//     },
//     yaxis: {
//       tooltip: {
//         enabled: true,
//       },
//     },
//     grid: {
//       show: false, // Hides all grid lines
//       xaxis: {
//         lines: {
//           show: true, // Hides vertical grid lines
//         },
//       },
//       yaxis: {
//         lines: {
//           show: true, // Hides horizontal grid lines
//         },
//       },
//     },
//   });

//   return (
//     <>

//       <div className='container mt-5 mb-5'>
//         <div id="chart">
//           <ReactApexChart options={options} series={series} type="candlestick" height={350} />
//         </div>
//       </div>
//     </>
//   );
// };
// export default CustomChart;


import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomChart = ({ symbol, theme }) => {
  const series = [
    {
      data: [
        {
          x: new Date(1538778600000), // First data point
          y: [6629.81, 6650.5, 6623.04, 6633.33], // [open, high, low, close]
        },
        {
          x: new Date(1538780400000),
          y: [6632.01, 6643.59, 6620, 6630.11],
        },
        {
          x: new Date(1538782200000),
          y: [6630.71, 6648.95, 6623.34, 6635.65],
        },
        {
          x: new Date(1538784000000),
          y: [6635.65, 6651, 6629.67, 6638.24],
        },
        {
          x: new Date(1538785800000),
          y: [6638.24, 6640, 6620, 6624.47],
        },
        {
          x: new Date(1538787600000),
          y: [6624.53, 6636.03, 6621.68, 6624.31],
        },
        {
          x: new Date(1538789400000),
          y: [6624.61, 6632.2, 6617, 6626.02],
        },
        {
          x: new Date(1538791200000),
          y: [6627, 6627.62, 6584.22, 6603.02],
        },
        {
          x: new Date(1538793000000),
          y: [6605, 6608.03, 6598.95, 6604.01],
        },
        {
          x: new Date(1538794800000),
          y: [6604.5, 6614.4, 6602.26, 6608.02],
        },
        {
          x: new Date(1538796600000),
          y: [6608.02, 6610.68, 6601.99, 6608.91],
        },
        {
          x: new Date(1538798400000),
          y: [6608.91, 6618.99, 6608.01, 6612],
        },
        {
          x: new Date(1538800200000),
          y: [6612, 6615.13, 6605.09, 6612],
        },
        {
          x: new Date(1538802000000),
          y: [6612, 6624.12, 6608.43, 6622.95],
        },
        {
          x: new Date(1538803800000),
          y: [6623.91, 6623.91, 6615, 6615.67],
        },
      ],
    },
  ];

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
      background: `{${theme} === "dark" ? '#00191e':'red'}`,
    },
    title: {
      text: symbol,
      align: 'left',
      style: {
        fontSize: '20px', // Title font size
        color: '#1BA4A4', // Title color
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
          color: '#1BA4A4', // X-axis labels color
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      labels: {
        style: {
          fontSize: '12px', // Y-axis labels font size
          color: '#1BA4A4', // Y-axis labels color
        },
      },
    },
    grid: {
      show: true, // Show grid lines
      borderColor: '#1BA4A4', // Grid lines color
      strokeDashArray: 4, // Grid lines dash array
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: false, // Show horizontal grid lines
        },
      },
    },
    colors: ['#3f51b5'], // Candlestick color
    tooltip: {
      enabled: true,
      theme: 'light', // Tooltip theme
      style: {
        fontSize: '14px', // Tooltip font size
      },
      x: {
        format: 'dd MMM yyyy', // Tooltip date format
      },
    },
  };

  return (
    <div className='container mt-5 mb-5'>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="candlestick" height={350} />
      </div>
    </div>
  );
};

export default CustomChart;
