import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const HistoryHeader = () => {
    let location = useLocation();
    return (
        <>

            <div className='history-header'>
                <ul>
                    <li className={location.pathname === "/deposithistory" && "active-lish-history"}>
                        <NavLink to="/deposithistory">Deposit History</NavLink>
                    </li>
                    <li className={location.pathname === "/withdrawhistory" && "active-lish-history"}>
                        <NavLink to="/withdrawhistory">Withdrawal History</NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default HistoryHeader
