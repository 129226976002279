import React, { useState, useEffect } from 'react';
import { base_url } from '../config/config';

const RecentTrades = ({ symbol }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${base_url}/recent_trades`);
        const data = await response.json();
        if (data.success === 1) {
          setTransactions(data.Data);
        } else {
          console.error('Error:', data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <div className='orderbook-card'>
      <div className='order-header'>
        <h4>Recent Trades</h4>
      </div>

      <div className='trade-table1 table-scroll3'>
        <table className="table tradeing-table-row">
          <thead>
            <tr>
              <th scope="col">Price (USD)</th>
              <th scope="col">Amount (AAVE)</th>
              <th scope="col">Total (USD)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>Loading...</td>
              </tr>
            ) : (
              transactions && transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  return (
                    <tr key={index} >
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{transaction.tokenPrice?.toFixed(2)}</td>
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{transaction.tokenQuantity?.toFixed(2)}</td>
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{(transaction.tokenPrice * transaction.tokenQuantity)?.toFixed(2)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No transactions available</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTrades;
