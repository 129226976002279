import React from 'react'
import sup from '../../assets/img/coin_b.png'
import user from '../../assets/img/user.png'
const Coin = () => {
  return (
    <>
         <section className="fees01">
            <div className="container">
                <div className="row banner column-reverse">
                    <div className="col-lg-6 col-md-6 align-self">
                        <div className="col_1"> 
                            <h1 className="w banner_heading outfit">List your asset</h1>
                            <p className="banner_text w outfit">Get listed on our crypto exchange quickly and easily</p>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-6 border_col'>
                                <div className='icon-box d-flex'>
                                    <div className='icon_box_img'>
                                        <img className='user_1' src={user}></img>
                                    </div>
                                    <div className='icon_box_textb'>
                                        <h4 className='icon_box_title outfit sb'>Ram Rajput</h4>
                                        <p className='icon_box_text outfit w'>Listing manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 align-self text-end'>
                                <a href='#' className='outfit sb link_text'>Ask on telegram direct</a>
                            </div>
                        </div>
                    </div>  
                    <div className="col-lg-6 col-md-6 text-center py-3">
                        <img className='banner_img_faq fees01_img' src={sup}></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="sec04">
                <div className="container">
                <h1 className="w banner_heading outfit text-center">Our services reflects your needs</h1>
                    <div className="margin_top ">
                        <div className="row mt-5">
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="icon_box-jaw icon_box-jaw1 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={user}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Fast Listing</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="icon_box-jaw icon_box-jaw2 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={user}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Building Hype Around Your Coin</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="icon_box-jaw icon_box-jaw3 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={user}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Security Is A Necessity</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <div className="icon_box-jaw icon_box-jaw4 text-center">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <img src={user}></img>
                                        </div>
                                    </div>
                                    <h5 className="profile_box_title text-center outfit w">Focus On Trade Volume</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </section>
        <section className='conin04'>
                <div className="container">
                    <h1 className="w banner_heading outfit text-center">List your asset</h1>
                    <p className="banner_text w outfit text-center">Please fill out the form below to get started. Provide your contact details and one of our sales <br></br> department managers will get  in touch with you as soon as possible</p>
                    <form>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <lable className='coin-lable outfit'>Project Name</lable>
                                <input className='coin-input outfit form-control mt-2' type='text' placeholder="Enter Project Name"></input>
                                <lable className='coin-lable outfit'>Smart Contract Address</lable>
                                <input className='coin-input outfit form-control mt-2' type='text' placeholder="Enter Address"></input>
                                <lable className='coin-lable outfit'>Contact Name</lable>
                                <input className='coin-input outfit form-control mt-2' type='text' placeholder="Enter Contact Name"></input>
                                <lable className='coin-lable outfit'>Telegram ID</lable>
                                <input className='coin-input outfit form-control mt-2' type='text' placeholder="Enter Telegram ID"></input>
                            </div>
                            <div className='col-lg-6'>
                                <lable className='coin-lable outfit'>Your e-mail address</lable>
                                <input className='coin-input outfit form-control mt-2' type='email' placeholder="Enter e-mail address"></input>
                                <lable className='coin-lable outfit'>Referred by (Optional)</lable>
                                <input className='coin-input outfit form-control mt-2' type='text' placeholder="Enter Referred"></input>
                                <lable className='coin-lable outfit'>Phone Number</lable>
                                <input className='coin-input outfit form-control mt-2' type='tel' placeholder="Enter Phone Number"></input>
                                <lable className='coin-lable outfit'>Comments </lable>
                                <textarea className='coin-input outfit form-control mt-2' row="1">Enter text here...</textarea>
                            </div>
                        </div>
                        <input type='submit' value="Send Rquest" className='coin_btn outfit'></input>
                    </form>
                </div>
        </section>
    </>
  )
}

export default Coin