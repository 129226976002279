// import React, { useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import Webcam from 'react-webcam';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';

// const UserSelfie = () => {
//     const [imgSrc, setImgSrc] = useState(null);
//     const [isWebcamActive, setIsWebcamActive] = useState(true);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const navigate = useNavigate();
//     const webcamRef = useRef(null);

//     const capture = () => {
//         const video = webcamRef.current.video;
//         const canvas = document.createElement('canvas');
//         canvas.width = video.videoWidth;
//         canvas.height = video.videoHeight;
//         const context = canvas.getContext('2d');

//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         canvas.toBlob((blob) => {
//             console.log(blob);
//             setImgSrc(blob);
//             setIsWebcamActive(false);
//         }, 'image/jpeg');
//     };

//     const handleSubmit = async () => {
//         if (!imgSrc) {
//             toast.dismiss();
//             toast.error('Please capture a selfie.');
//             return;
//         }

//         setIsSubmitting(true); // Start submitting

//         const token = localStorage.getItem("token");
//         try {
//             const formData = new FormData();
//             formData.append('signatureImage', imgSrc);

//             const response = await fetch(`${base_url}/upload_signature_image`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });

//             if (response.ok) {
//                 const data = await response.json();
//                 console.log(data.message); 
//                 toast.dismiss();
//                 toast.success(data.message);
//                 // Redirect to dashboard after successful upload
//                 navigate('/dashboard');
//             } else {
//                 const errorData = await response.json();
//                 throw new Error(errorData.message || 'Failed to upload image');
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             toast.dismiss();
//             toast.error(error.message || 'Error occurred while uploading selfie.');
//         } finally {
//             setIsSubmitting(false); // Stop submitting
//         }
//     };

//     return (
//         <>

//             <section className='kyc-profile-main'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                             <ProfileTopHeader />
//                                 <div className='kyc-01'>
//                                     <div className='container'>
//                                         <div className='kyc-row'>
//                                             <div className='kyc-01-heading'>
//                                                 <span className="number">04</span>
//                                                 <div className=''>
//                                                     <h5>Selfie Upload</h5>
//                                                     To Verify Your Identity, Please take Your Selfie
//                                                 </div>
//                                             </div>
//                                             <div className='kyc-tab-main'>
//                                                 <div className='tab-form-main'>
//                                                     <div className='mt-5'>
//                                                         <div className='row'>
//                                                             <div className='form-group col-md-12 profile-image text-center'>
//                                                                 {isWebcamActive ? (
//                                                                     <Webcam
//                                                                         audio={false}
//                                                                         ref={webcamRef}
//                                                                         screenshotFormat="image/jpeg"
//                                                                         className="webcam-preview"
//                                                                         style={{ height: "300px", width: "440px" }}
//                                                                     />
//                                                                 ) : (
//                                                                     <img src={URL.createObjectURL(imgSrc)} alt="Captured selfie" className="mt-2 selfie-image" />
//                                                                 )}
//                                                                 <br />
//                                                                 {isWebcamActive && <button onClick={capture} className="btn_upload mt-2">Capture</button>}
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="text-center mt-5">
//                                                         <button type='button' className="next-btn mt-2" onClick={handleSubmit} disabled={isSubmitting}>
//                                                             {isSubmitting ? 'Submitting...' : 'Submit'}
//                                                         </button>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default UserSelfie;










import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import Webcam from 'react-webcam';
import ProfileSidebar from '../ProfilePages/ProfileSidebar';
import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
import { ColorRing } from 'react-loader-spinner';
import PuffLoader from 'react-spinners/PuffLoader';
const UserSelfie = () => {
    const [imgSrc, setImgSrc] = useState(null);
    const [isWebcamActive, setIsWebcamActive] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false); // Add uploadingImage state
    const navigate = useNavigate();
    const webcamRef = useRef(null);

    const capture = () => {
        const video = webcamRef.current.video;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
            console.log(blob);
            setImgSrc(blob);
            setIsWebcamActive(false);
        }, 'image/jpeg');
    };

    const handleSubmit = async () => {
        if (!imgSrc) {
            toast.dismiss();
            toast.error('Please capture a selfie.');
            return;
        }

        setIsSubmitting(true); // Start submitting
        setUploadingImage(true); // Start uploading image
        const token = localStorage.getItem("token");
        try {
            const formData = new FormData();
            formData.append('signatureImage', imgSrc);

            const response = await fetch(`${base_url}/upload_signature_image`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                toast.dismiss();
                toast.success(data.message);
                // Redirect to dashboard after successful upload
                navigate('/dashboard');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to upload image');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.dismiss();
            toast.error(error.message || 'Error occurred while uploading selfie.');
        } finally {
            setIsSubmitting(false); // Stop submitting
            setUploadingImage(false); // Stop uploading image
        }
    };
    return (
        <>
            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='kyc-01'>

                                    <div className='kyc-row'>
                                        <div className='kyc-01-heading'>
                                            <span className="number">04</span>
                                            <div className=''>
                                                <h5>Selfie Upload</h5>
                                                <p>To Verify Your Identity, Please take Your Selfie</p>
                                            </div>
                                        </div>
                                        <div className='kyc-tab-main'>
                                            <div className='tab-form-main'>
                                                <div className='mt-5 margin-0'>
                                                    <div className='row'>
                                                        <div className='form-group col-md-12 profile-image text-center'>
                                                            {isWebcamActive ? (
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={webcamRef}
                                                                    screenshotFormat="image/jpeg"
                                                                    className="webcam-preview"
                                                                    style={{ height: "300px", width: "440px" }}
                                                                />
                                                            ) : (
                                                                <img src={URL.createObjectURL(imgSrc)} alt="Captured selfie" className="mt-2 selfie-image" />
                                                            )}
                                                            <br />
                                                            {isWebcamActive && <button onClick={capture} className="btn_upload mt-2">Capture</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-5">
                                                    {isSubmitting || uploadingImage ? (
                                                        <div>
                                                            {/* <ColorRing color="#00BFFF" height={50} width={50} /> */}
                                                            <div className='loader-container'>
                                                                <PuffLoader color="#36d7b7" cssOverride={{ textAlign: 'center' }} size={30} />
                                                            </div>
                                                            <p>{uploadingImage ? 'Please wait, image is uploading...' : 'Submitting...'}</p>
                                                        </div>
                                                    ) : (
                                                        <button type='button' className="next-btn mt-2" onClick={handleSubmit} disabled={isSubmitting}>
                                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default UserSelfie;
