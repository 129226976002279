// import React, { useState } from 'react';
// import { FaArrowsRotate } from 'react-icons/fa6';
// import id1 from '../../assets/img/id-1.png';
// // import id2 from '../../assets/img/id_f.png';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import KycTab from './KycTab';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
// import { ColorRing } from 'react-loader-spinner';

// const UserKyc = () => {
//     const [selectedDocument, setSelectedDocument] = useState('adhaar_card');
//     const [frontImage, setFrontImage] = useState('');
//     const [backImage, setBackImage] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [uploadingImage, setUploadingImage] = useState(false);
//     const [frontImageUrl, setFrontImageUrl] = useState('');
//     const [backImageUrl, setBackImageUrl] = useState('');

//     const navigate = useNavigate();

//     const validateAadharNumber = (aadharNumber) => {
//         const aadharPattern = /^\d{4}\s\d{4}\s\d{4}$/;
//         return aadharPattern.test(aadharNumber);
//     };

//     const validateDrivinglicenceNumber = (licenceNumber) => {
//         // Indian driving licence format: XX99 9999999999
//         const licencePattern = /^[A-Z]{2}\d{2}\s\d{11}$/;
//         return licencePattern.test(licenceNumber);
//     };

//     const validatePassportNumber = (passportNumber) => {
//         // Indian passport format: A1234567
//         const passportPattern = /^[A-Z]\d{7}$/;
//         return passportPattern.test(passportNumber);
//     };
//     const handleSubmit = async () => {
//         setLoading(true);

//         const documentNumber = document.getElementById('documentNumber').value;
//         let isValid = true;
//         switch (selectedDocument) {
//             case 'adhaar_card':
//                 isValid = validateAadharNumber(documentNumber);
//                 break;
//             case 'licence':
//                 isValid = validateDrivinglicenceNumber(documentNumber);
//                 break;
//             case 'passport':
//                 isValid = validatePassportNumber(documentNumber);
//                 break;
//             default:
//                 isValid = false;
//         }
//         if (!documentNumber || !frontImage || !backImage) {
//             toast.dismiss();
//             toast.error('Please fill in all the details and upload both front and back images.');
//             return;
//         }

//         const token = localStorage.getItem("token");
//         try {
//             const formattedDocumentType = selectedDocument.toLowerCase();
//             const formattedDocumentTypeWithUnderscores = formattedDocumentType.replace(/ /g, '_');

//             // Upload document details
//             const response1 = await fetch(`${base_url}/doc_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     documentType: formattedDocumentTypeWithUnderscores,
//                     documentNumber: documentNumber
//                 })
//             });
//             const data1 = await response1.json();

//             if (data1.success === 1) {
//                 toast.dismiss();
//                 toast.success(data1.message);
//                 setLoading(false);
//             } else {
//                 toast.dismiss();
//                 toast.error(data1.message);
//                 return; // Stop execution if document details submission fails
//             }

//             // Upload proof images
//             const formData = new FormData();
//             formData.append('documentFront', frontImage);
//             formData.append('documentBack', backImage);
//             setUploadingImage(true);
//             const response2 = await fetch(`${base_url}/upload_proof_images`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });
//             console.log('frontImage', frontImage);
//             console.log('backImage', backImage);

//             const data2 = await response2.json();

//             if (data2.success === 1) {
//                 // toast.dismiss();
//                 // toast.success(data2.message);
//                 navigate('/UserSelfie');
//                 setLoading(false); // Reset loading state
//                 setUploadingImage(false);

//             } else {
//                 setLoading(true);
//                 // toast.dismiss();
//                 // toast.error(data2.message);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             // toast.dismiss();
//             // toast.error(error.message);
//         }
//     };

//     const handleFrontImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file && file.type.startsWith('image')) {
//             setFrontImage(file);
//             setFrontImageUrl(URL.createObjectURL(file));
//         } else {
//             // toast.dismiss();
//             // toast.error('Please select an image file for the front image.');
//         }
//     };

//     const handleBackImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file && file.type.startsWith('image')) {
//             setBackImage(file);
//             setBackImageUrl(URL.createObjectURL(file));
//         } else {
//             toast.dismiss();
//             toast.error('Please select an image file for the back image.');
//         }
//     };

//     return (
//         <>

//             <section className='kyc-profile-main'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                             <ProfileTopHeader />
//                                 <div className='kyc-01'>
//                                     <div className='container'>
//                                         {/* <KycTab /> */}
//                                         <div className='kyc-row'>

//                                             <div className='kyc-01-heading'>
//                                                 <sapn className="number">03</sapn>
//                                                 <div className=''>
//                                                     <h5>Document Upload</h5>
//                                                     To Verify Your Identity, Please Upload Any Of Your Document
//                                                 </div>
//                                             </div>

//                                             <div className='kyc-tab-main'>
//                                                  <div className='tab-form-main'>
//                                                 {/* <div className='kyc-01-heading'>
//                                                     <div>
//                                                     <h5>ID Card Details </h5>
//                                                     Choose an option with the buttons below
//                                                     </div>

//                                                 </div> */}
//                                                     <ul className="nav nav-tabs kyc-tab1" id="myTab" role="tablist">
//                                                         <li className="nav-item kyc-nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link active" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => setSelectedDocument('adhaar_card')}>Aadhar card</button>
//                                                         </li>
//                                                         <li className="nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setSelectedDocument('licence')}>Driving Licence</button>
//                                                         </li>
//                                                         <li className="nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => setSelectedDocument('passport')}>Passport</button>
//                                                         </li>
//                                                     </ul>
//                                                     <form className=' mt-5'>
//                                                         {/* <div id="show1" className='tab-div-show1 tab-show-bottom '> */}
//                                                         <div className='row'>
//                                                             <div className='col-md-12'>
//                                                                 <div className='form-inner-heading mt-2 mb-4'>
//                                                                     <span><FaArrowsRotate className='drop-ic' /></span>
//                                                                     <h4> {selectedDocument} Details</h4>
//                                                                 </div>
//                                                             </div>
//                                                             <div className='form-group col-md-12'>
//                                                                 <label>Enter {selectedDocument} No</label>

//                                                                 <input
//                                                                     type="text"
//                                                                     id="documentNumber"
//                                                                     placeholder={
//                                                                         selectedDocument === 'adhaar_card' ? 'i.e  737482749278 ' :
//                                                                             selectedDocument === 'licence' ? 'i.e  HR85 20240000984 ' :
//                                                                                 selectedDocument === 'passport' ? ' i.e AB1234567 ' :
//                                                                                     ''
//                                                                     }
//                                                                     className='form-control mt-2'
//                                                                 />

//                                                                 {/* <input type="text" id="documentNumber" placeholder={`Enter ${selectedDocument} No`} className='form-control mt-2' /> */}
//                                                             </div>


//                                                             <div className='form-group col-md-6 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="frontImage">
//                                                                     <img src={frontImageUrl || id1} alt="Front" />
//                                                                     <input type="file" id="frontImage" style={{ display: "none" }} onChange={handleFrontImageChange} />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-3" htmlFor="frontImage">Select Front </label>
//                                                                 </div>
//                                                             </div>
//                                                             <div className='form-group col-md-6 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="backImage">
//                                                                     <img src={backImageUrl || id1} alt="Back" />
//                                                                     <input type="file" id="backImage" style={{ display: "none" }} onChange={handleBackImageChange} />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-3" htmlFor="backImage">Select Back </label>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         {/* <div className="form-group col-md-12 text-center mt-5">
//                                                             <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit </button>
//                                                         </div> */}
//                                                         <div className="form-group col-md-12 text-center mt-5">
//                                                             {loading ? (
//                                                                 <ColorRing color="#00BFFF" height={50} width={50} />
//                                                             ) : (
//                                                                 <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                             )}
//                                                             {uploadingImage && loading && (
//                                                                 <p>Please wait, image is uploading...</p>
//                                                             )}
//                                                         </div>

//                                                     </form>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>



//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>


//         </>
//     )
// }

// export default UserKyc;




// import React, { useState } from 'react';
// import { FaArrowsRotate } from 'react-icons/fa6';
// import id1 from '../../assets/img/id-1.png';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import KycTab from './KycTab';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
// import { ColorRing } from 'react-loader-spinner';

// const UserKyc = () => {
//     const [selectedDocument, setSelectedDocument] = useState('adhaar_card');
//     const [frontImage, setFrontImage] = useState('');
//     const [backImage, setBackImage] = useState('');
//     const [frontImageUrl, setFrontImageUrl] = useState('');
//     const [backImageUrl, setBackImageUrl] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [uploadingImage, setUploadingImage] = useState(false);
//     const [imageUploadError, setImageUploadError] = useState(false); // New state
//     const navigate = useNavigate();

//     const validateAadharNumber = (aadharNumber) => {
//         const aadharPattern = /^\d{4}\s\d{4}\s\d{4}$/;
//         return aadharPattern.test(aadharNumber);
//     };

//     const validateDrivinglicenceNumber = (licenceNumber) => {
//         const licencePattern = /^[A-Z]{2}\d{2}\s\d{11}$/;
//         return licencePattern.test(licenceNumber);
//     };

//     const validatePassportNumber = (passportNumber) => {
//         const passportPattern = /^[A-Z]\d{7}$/;
//         return passportPattern.test(passportNumber);
//     };

//     const handleSubmit = async () => {
//         const documentNumber = document.getElementById('documentNumber').value;
//         let isValid = true;
//         switch (selectedDocument) {
//             case 'adhaar_card':
//                 isValid = validateAadharNumber(documentNumber);
//                 break;
//             case 'licence':
//                 isValid = validateDrivinglicenceNumber(documentNumber);
//                 break;
//             case 'passport':
//                 isValid = validatePassportNumber(documentNumber);
//                 break;
//             default:
//                 isValid = false;
//         }
//         if (!documentNumber || !frontImage || !backImage) {
//             toast.dismiss();
//             toast.error('Please fill in all the details and upload both front and back images.');
//             return;
//         }
//         setLoading(true);
//         const token = localStorage.getItem("token");
//         try {
//             const formattedDocumentType = selectedDocument.toLowerCase();
//             const formattedDocumentTypeWithUnderscores = formattedDocumentType.replace(/ /g, '_');

//             const response1 = await fetch(`${base_url}/doc_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     documentType: formattedDocumentTypeWithUnderscores,
//                     documentNumber: documentNumber
//                 })
//             });
//             const data1 = await response1.json();
//             if (data1.success === 1) {
//                 toast.dismiss();
//                 toast.success(data1.message);
//                 setLoading(false);
//             } else {
//                 toast.dismiss();
//                 toast.error(data1.message);
//                 setLoading(false);
//                 return;
//             }

//             const formData = new FormData();
//             formData.append('documentFront', frontImage);
//             formData.append('documentBack', backImage);
//             setUploadingImage(true);
//             const response2 = await fetch(`${base_url}/upload_proof_images`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });

//             const data2 = await response2.json();

//             if (data2.success === 1) {
//                 setUploadingImage(false);
//                 setLoading(false);
//                 setImageUploadError(false); // Reset error state
//                 // navigate('/UserSelfie');
//             } else {
//                 setUploadingImage(false);
//                 setLoading(false);
//                 setImageUploadError(true); // Set error state
//                 toast.dismiss();
//                 toast.error(data2.message);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setLoading(false);
//             setUploadingImage(false);
//             setImageUploadError(true); // Set error state
//         }
//     };

//     const handleFrontImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file && file.type.startsWith('image')) {
//             setFrontImage(file);
//             setFrontImageUrl(URL.createObjectURL(file));
//         } else {
//             toast.dismiss();
//             toast.error('Please select an image file for the front image.');
//         }
//     };

//     const handleBackImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file && file.type.startsWith('image')) {
//             setBackImage(file);
//             setBackImageUrl(URL.createObjectURL(file));
//         } else {
//             toast.dismiss();
//             toast.error('Please select an image file for the back image.');
//         }
//     };

//     return (
//         <>
//             <section className='kyc-profile-main'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                                 <ProfileTopHeader />
//                                 <div className='kyc-01'>

//                                         <div className='kyc-row'>
//                                             <div className='kyc-01-heading'>
//                                                 <span className="number">03</span>
//                                                 <div>
//                                                     <h5>Document Upload</h5>
//                                                     <p>To Verify Your Identity, Please Upload Any Of Your Document</p>
//                                                 </div>
//                                             </div>
//                                             <div className='kyc-tab-main'>
//                                                 <div className='tab-form-main'>
//                                                     <ul className="nav nav-tabs kyc-tab1" id="myTab" role="tablist">
//                                                         <li className="nav-item kyc-nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link active" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => setSelectedDocument('adhaar_card')}>Aadhar card</button>
//                                                         </li>
//                                                         <li className="nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setSelectedDocument('licence')}>Driving Licence</button>
//                                                         </li>
//                                                         <li className="nav-item" role="presentation">
//                                                             <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => setSelectedDocument('passport')}>Passport</button>
//                                                         </li>
//                                                     </ul>
//                                                     <form className=' mt-5'>
//                                                         <div className='row'>
//                                                             <div className='col-md-12'>
//                                                                 <div className='form-inner-heading mt-2 mb-4'>
//                                                                     <span><FaArrowsRotate className='drop-ic' /></span>
//                                                                     <h4> {selectedDocument} Details</h4>
//                                                                 </div>
//                                                             </div>
//                                                             <div className='form-group col-md-12'>
//                                                                 <label>Enter {selectedDocument} No</label>
//                                                                 <input
//                                                                     type="text"
//                                                                     id="documentNumber"
//                                                                     placeholder={
//                                                                         selectedDocument === 'adhaar_card' ? 'i.e  737482749278 ' :
//                                                                             selectedDocument === 'licence' ? 'i.e  HR85 20240000984 ' :
//                                                                                 selectedDocument === 'passport' ? ' i.e AB1234567 ' :
//                                                                                     ''
//                                                                     }
//                                                                     className='form-control mt-2'
//                                                                 />
//                                                             </div>
//                                                             <div className='form-group col-md-6 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="frontImage">
//                                                                     <img src={frontImageUrl || id1} alt="Front" />
//                                                                     <input type="file" id="frontImage" style={{ display: "none" }} onChange={handleFrontImageChange} />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-3" htmlFor="frontImage">Select Front </label>
//                                                                 </div>
//                                                             </div>
//                                                             <div className='form-group col-md-6 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="backImage">
//                                                                     <img src={backImageUrl || id1} alt="Back" />
//                                                                     <input type="file" id="backImage" style={{ display: "none" }} onChange={handleBackImageChange} />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-3" htmlFor="backImage">Select Back </label>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className="form-group col-md-12 text-center mt-5">
//                                                             {(loading || uploadingImage) ? ( // Show loading spinner when loading or uploading
//                                                                 <ColorRing color="#08d1d1,#08d1d1,#08d1d1,#08d1d1,#08d1d1" height={50} width={50} />
//                                                             ) : (
//                                                                 <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                             )}
//                                                             {(uploadingImage || loading || imageUploadError) && ( // Show message when uploading, loading, or error
//                                                                 <p>Please wait, image is uploading...</p>
//                                                             )}
//                                                         </div>
//                                                     </form>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default UserKyc;



import React, { useState } from 'react';
import { FaArrowsRotate } from 'react-icons/fa6';
import id1 from '../../assets/img/id-1.png';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import KycTab from './KycTab';
import ProfileSidebar from '../ProfilePages/ProfileSidebar';
import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
import { ColorRing } from 'react-loader-spinner';
import PuffLoader from 'react-spinners/PuffLoader';

const UserKyc = () => {
    const [selectedDocument, setSelectedDocument] = useState('adhaar_card');
    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [frontImageUrl, setFrontImageUrl] = useState('');
    const [backImageUrl, setBackImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageUploadError, setImageUploadError] = useState(false); // New state
    const navigate = useNavigate();

    const validateAadharNumber = (aadharNumber) => {
        const aadharPattern = /^\d{4}\s\d{4}\s\d{4}$/;
        return aadharPattern.test(aadharNumber);
    };

    const validateDrivinglicenceNumber = (licenceNumber) => {
        const licencePattern = /^[A-Z]{2}\d{2}\s\d{11}$/;
        return licencePattern.test(licenceNumber);
    };

    const validatePassportNumber = (passportNumber) => {
        const passportPattern = /^[A-Z]\d{7}$/;
        return passportPattern.test(passportNumber);
    };

    const handleSubmit = async () => {
        const documentNumber = document.getElementById('documentNumber').value;
        let isValid = true;
        switch (selectedDocument) {
            case 'adhaar_card':
                isValid = validateAadharNumber(documentNumber);
                break;
            case 'licence':
                isValid = validateDrivinglicenceNumber(documentNumber);
                break;
            case 'passport':
                isValid = validatePassportNumber(documentNumber);
                break;
            default:
                isValid = false;
        }
        if (!documentNumber || !frontImage || !backImage) {
            toast.dismiss();
            toast.error('Please fill in all the details and upload both front and back images.');
            return;
        }
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
            const formattedDocumentType = selectedDocument.toLowerCase();
            const formattedDocumentTypeWithUnderscores = formattedDocumentType.replace(/ /g, '_');

            const response1 = await fetch(`${base_url}/doc_details`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    documentType: formattedDocumentTypeWithUnderscores,
                    documentNumber: documentNumber
                })
            });
            const data1 = await response1.json();


            const formData = new FormData();
            formData.append('documentFront', frontImage);
            formData.append('documentBack', backImage);
            setUploadingImage(true);
            const response2 = await fetch(`${base_url}/upload_proof_images`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                },
                body: formData
            });

            const data2 = await response2.json();

            if (data2.success === 1) {
                setUploadingImage(false);
                setLoading(false);
                setImageUploadError(false); // Reset error state
                if (data1.success === 1) {
                    toast.dismiss();
                    toast.success(data1.message);
                    setLoading(false);
                    navigate('/UserSelfie');
                } else {
                    toast.dismiss();
                    toast.error(data1.message);
                    setLoading(false);
                    return;
                }

            } else {
                setUploadingImage(false);
                setLoading(false);
                setImageUploadError(true); // Set error state
                toast.dismiss();
                toast.error(data2.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setUploadingImage(false);
            setImageUploadError(true); // Set error state
        }
    };

    const handleFrontImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image')) {
            setFrontImage(file);
            setFrontImageUrl(URL.createObjectURL(file));
        } else {
            toast.dismiss();
            toast.error('Please select an image file for the front image.');
        }
    };

    const handleBackImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image')) {
            setBackImage(file);
            setBackImageUrl(URL.createObjectURL(file));
        } else {
            toast.dismiss();
            toast.error('Please select an image file for the back image.');
        }
    };

    return (
        <>
            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='kyc-01'>

                                    <div className='kyc-row'>
                                        <div className='kyc-01-heading'>
                                            <span className="number">03</span>
                                            <div>
                                                <h5>Document Upload</h5>
                                                <p>To Verify Your Identity, Please Upload Any Of Your Document</p>
                                            </div>
                                        </div>
                                        <div className='kyc-tab-main'>
                                            <div className='tab-form-main'>
                                                <ul className="nav nav-tabs kyc-tab1" id="myTab" role="tablist">
                                                    <li className="nav-item kyc-nav-item" role="presentation">
                                                        <button className="nav-link kyc-nav-link active" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => setSelectedDocument('adhaar_card')}>Aadhar card</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setSelectedDocument('licence')}>Driving Licence</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link kyc-nav-link" data-bs-toggle="tab" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => setSelectedDocument('passport')}>Passport</button>
                                                    </li>
                                                </ul>
                                                <form className=' mt-5'>
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className='form-inner-heading mt-2 mb-4'>
                                                                <span><FaArrowsRotate className='drop-ic' /></span>
                                                                <h4> {selectedDocument} Details</h4>
                                                            </div>
                                                        </div>
                                                        <div className='form-group col-md-12'>
                                                            <label>Enter {selectedDocument} No</label>
                                                            <input
                                                                type="text"
                                                                id="documentNumber"
                                                                placeholder={
                                                                    selectedDocument === 'adhaar_card' ? 'i.e  737482749278 ' :
                                                                        selectedDocument === 'licence' ? 'i.e  HR85 20240000984 ' :
                                                                            selectedDocument === 'passport' ? ' i.e AB1234567 ' :
                                                                                ''
                                                                }
                                                                className='form-control mt-2'
                                                            />
                                                        </div>
                                                        <div className='form-group col-md-6 mt-5 profile-image text-center'>
                                                            <label htmlFor="frontImage">
                                                                <img src={frontImageUrl || id1} alt="Front" />
                                                                <input type="file" id="frontImage" style={{ display: "none" }} onChange={handleFrontImageChange} />
                                                            </label>
                                                            <div className="text-center">
                                                                <label className="btn_upload mt-3" htmlFor="frontImage">Select Front </label>
                                                            </div>
                                                        </div>
                                                        <div className='form-group col-md-6 mt-5 profile-image text-center'>
                                                            <label htmlFor="backImage">
                                                                <img src={backImageUrl || id1} alt="Back" />
                                                                <input type="file" id="backImage" style={{ display: "none" }} onChange={handleBackImageChange} />
                                                            </label>
                                                            <div className="text-center">
                                                                <label className="btn_upload mt-3" htmlFor="backImage">Select Back </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-12 text-center mt-5">
                                                        {(loading || uploadingImage) ? ( // Show loading spinner when loading or uploading
                                                            // <ColorRing color="#08d1d1,#08d1d1,#08d1d1,#08d1d1,#08d1d1" height={50} width={50} />
                                                            <div className='loader-container'>
                                                                <PuffLoader color="#36d7b7" cssOverride={{ textAlign: 'center' }} size={30} />
                                                            </div>
                                                        ) : (
                                                            <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
                                                        )}
                                                        {(uploadingImage || loading || imageUploadError) && ( // Show message when uploading, loading, or error
                                                            <p>Please wait, image is uploading...</p>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default UserKyc;