import React, { useState, useEffect } from 'react';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { NavLink, useNavigate } from 'react-router-dom';
import toast, { clearAll } from 'react-hot-toast';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import _fetch from '../config/api';
import { base_url } from '../config/config';
import s1 from '../assets/img/img-login.png';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [phone, setPhone] = useState({ countryCode: '', phone: '' });
    const [activeTab, setActiveTab] = useState('email');
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [phoneCountdown, setPhoneCountdown] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [sendingEmailOTP, setSendingEmailOTP] = useState(false);
    const [sendingPhoneOTP, setSendingPhoneOTP] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    useEffect(() => {
        let timer;
        if (emailCountdown > 0) {
            timer = setTimeout(() => {
                setEmailCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [emailCountdown]);

    useEffect(() => {
        let timer;
        if (phoneCountdown > 0) {
            timer = setTimeout(() => {
                setPhoneCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [phoneCountdown]);




    const sendEmailOTP = async () => {
        if (!emailIsValid(email)) {
            toast.dismiss();
            toast.error('Invalid Email address');
            return;
        }

        try {
            if (isToastDisplayed) return;
            setSendingEmailOTP(true);
            setIsToastDisplayed(true);
            const requestData = {
                register_type: 'email',
                email,
            };

            const response = await _fetch(`${base_url}/auth/userOtp`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response.message);
                console.log(response)
                setEmailCountdown(180);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        } finally {
            setSendingEmailOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const sendPhoneOTP = async () => {
        const phoneNumber = phone.phone.substring(phone.countryCode.length);
        const formattedPhoneNumber = `+${phone.countryCode}${phoneNumber}`;




        if (!validatePhone(phoneNumber)) {
            toast.dismiss();
            toast.error('Invalid Phone Number');
            return;
        }

        try {
            if (isToastDisplayed) return;
            setSendingPhoneOTP(true);
            setIsToastDisplayed(true);

            const requestData = {
                register_type: 'phone',
                phone: phoneNumber,
                country_code: `+${phone.countryCode}`,
            };

            const response = await _fetch(`${base_url}/auth/userOtp`, 'POST', requestData);

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response.message);
                setPhoneCountdown(180);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error sending phone OTP:', error);
            toast.error('An error occurred while sending OTP to phone');
        } finally {
            setSendingPhoneOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };



    const handleEmailLogin = async () => {
        if (!email || !password) {
            toast.dismiss();
            toast.error('Please enter Email and Password');
            return;
        }
        try {
            if (isToastDisplayed) return;
            setLoggingIn(true);
            setIsToastDisplayed(true);
            const requestData = {
                email,
                password,
                register_type: 'email',
                otp: verificationCode
            };

            const response = await _fetch(`${base_url}/auth/verify_signin`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.success(response.message);
                localStorage.setItem("token", response.user.accessToken);
                navigate('/dashboard');
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error with email login:', error);
            toast.error('An error occurred during email login');
        } finally {
            setLoggingIn(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const handlePhoneLogin = async () => {
        if (!phone.phone || !phone.countryCode || !password) {
            toast.dismiss();
            toast.error('Please enter Phone number, Country Code and Password ');
            return;
        }

        // const phoneNumber = phone.phone.substring(phone.countryCode.length);
        const phoneNumber = phone.phone.substring(phone.countryCode.length);

        try {
            if (isToastDisplayed) return;
            setLoggingIn(true);
            setIsToastDisplayed(true);

            const requestData = {
                phone: phoneNumber,
                password,
                country_code: `+${phone.countryCode}`,
                register_type: 'phone',
                otp: verificationCode
            };

            const response = await _fetch(`${base_url}/auth/verify_signin`, 'POST', requestData);

            if (response.success === 1) {
                toast.success(response.message);
                localStorage.setItem("token", response.user.accessToken);
                navigate('/dashboard');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error with phone login:', error);
            toast.error('An error occurred during phone login');
        } finally {
            setLoggingIn(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhone = (phoneNumber) => {
        const pattern = phonePatterns[phone.countryCode];
        if (pattern) {
            return pattern.test(phoneNumber);
        }
        return /^\+?[1-9]\d{1,14}$/.test(phoneNumber);
    };

    const phonePatterns = {
        'US': /^\+1\d{10}$/,
    };

    return (
        <>
            <section className='sec1'>
                <div className='container-fluid p-0'>
                    <div className='login-row'>
                        <div className='login-column-div1'>
                            <div className='login-image-div'>
                                <img src={s1} alt="" />
                            </div>
                        </div>
                        <div className='login-column-div2'>

                            <div className='form-div'>
                                <div className='login-page-title'>
                                    <h4>Login</h4>
                                </div>
                                {/* <ul className="nav nav-pills" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link navlink-1 ${activeTab === 'email' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('email')}
                                            data-bs-toggle="pill"
                                            href="#home"
                                        >
                                            Email
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link navlink-1 ${activeTab === 'phone' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('phone')}
                                            data-bs-toggle="pill"
                                            href="#menu1"
                                        >
                                            Phone
                                        </a>
                                    </li>
                                </ul> */}
                                <div className="tab-content">
                                    <div id="home" className={` tab-pane ${activeTab === 'email' ? 'active' : ''} login-form`}>
                                        <form className='mt-3'>
                                            <div className='email-flex-div1 d-flex w-100'>
                                                <div className='w-100 form-group'>
                                                    <label>Email</label>
                                                    <input type="text" placeholder='Enter Your Email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control mt-2' autoComplete='off' />
                                                </div>
                                                <div className='otp-btn-div'>
                                                    <button type='button' onClick={sendEmailOTP} disabled={emailCountdown > 0 || sendingEmailOTP} className='btn otp-btn'>{sendingEmailOTP ? 'Sending...' : (emailCountdown > 0 ? `(${emailCountdown}s)` : 'Send OTP')}</button>
                                                </div>
                                            </div>
                                            <div className='form-group mt-3'>
                                                <label>Email Verification Code</label>
                                                <input type="text" placeholder="Enter Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control mt-2" autoComplete='off' />
                                            </div>
                                            <div className='form-group mt-3 password-div'>
                                                <label>Password</label>
                                                <input type={isVisible ? "text" : "password"} placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                    {
                                                        isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                    }
                                                </span>
                                            </div>
                                            <div className='form-group mt-3 text-end forgot-div'>
                                                <span className='text-end'>
                                                    <NavLink to="/forget">Forgot Password</NavLink>
                                                </span>
                                            </div>
                                            <div className='form-group mt-4'>
                                                <button type='button' onClick={handleEmailLogin} disabled={loggingIn} className='btn login-btn'>{loggingIn ? 'Logging In...' : 'Log In'}</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="menu1" className={`tab-pane ${activeTab === 'phone' ? 'active' : ''} login-form`}>
                                        <form className='mt-3'>
                                            <div className='email-flex-div1 d-flex'>
                                                <div className='form-group w-100'>
                                                    <label>Phone</label>
                                                    <PhoneInput
                                                        country={'in'}
                                                        value={phone.phone}
                                                        onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        inputClass={'form-control w-100 mt-2'}
                                                    />
                                                </div>
                                                <div className='otp-btn-div'>
                                                    <button type='button' onClick={sendPhoneOTP} disabled={phoneCountdown > 0 || sendingPhoneOTP} className='btn otp-btn'>{sendingPhoneOTP ? 'Sending...' : (phoneCountdown > 0 ? `(${phoneCountdown}s)` : 'Send OTP')}</button>
                                                </div>
                                            </div>
                                            <div className='form-group mt-3'>
                                                <label>Phone Verification Code</label>
                                                <input type="text" placeholder="Enter Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control mt-2" />
                                            </div>
                                            <div className='form-group mt-3 password-div'>
                                                <label>Password</label>
                                                <input type={isVisible ? "text" : "password"} placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                    {
                                                        isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                    }
                                                </span>
                                            </div>
                                            <div className='form-group mt-3 text-end forgot-div'>
                                                <span className='text-end'>
                                                    <NavLink to="/forget">Forgot Password</NavLink>
                                                </span>
                                            </div>
                                            <div className='form-group mt-4'>
                                                <button type='button' onClick={handlePhoneLogin} disabled={loggingIn} className='btn login-btn'>{loggingIn ? 'Logging In...' : 'Log In'}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='form-footer mt-3 text-center'>
                                    <p>Don't have an account? <NavLink to="/signup">Sign Up here</NavLink> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;

