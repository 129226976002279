import React from 'react'
import HistoryHeader from './HistoryHeader'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import InnerHeader from './InnerHeader'
import WithdrawList from './WithdrawList'
import { base_url } from '../../config/config'
import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import dateFormat from 'dateformat';


const WithdrawHistory = () => {
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    // const [page] = useState(1);


    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        fetchWithdrawalHistory(skip);
    }, [currentPage, limit]);

    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    // const handlePageClick = (e, page) => {
    //     e.preventDefault();
    //     setCurrentPage(page);
    // };



    const fetchWithdrawalHistory = async (skip = 0) => {
        try {
            const token = localStorage.getItem("token");


            const response = await fetch(`${base_url}/withdrawHistory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                body: JSON.stringify({
                    limit: limit,
                    skip: skip
                })
            });

            const data = await response.json();
            console.log('withdrawHistory-->11', data);
            if (data.success === 1) {
                setWithdrawalHistory(data.transaction);
                setTotal(data.total)
            } else {
                console.error("Error: ", data.message);
            }
        } catch (error) {
            console.error("Error fetching withdrawal history: ", error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // console.log('withdrawalHistory--->', withdrawalHistory);
    return (
        <>
            <InnerHeader />
            <section className='history-page'>
                <div className='container'>
                    <HistoryHeader />

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='market-table mb-5'>
                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Id</th>
                                            <th scope="col">Token</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Network</th>
                                            <th scope="col">Network</th>
                                            <th scope="col">Wallet Address</th>
                                            <th scope="col">Blockchain Record</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(withdrawalHistory.length > 0) ? (
                                            withdrawalHistory?.map((withdrawal, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                                            {/* <td>{index + 1}</td> */}
                                                            <td>{withdrawal._id}</td>
                                                            <td>{withdrawal.tokenName}</td>
                                                            <td>{withdrawal.amount}</td>
                                                            <td>{withdrawal.network_id}</td>
                                                            <td>{withdrawal.networkname}</td>
                                                            <td>{withdrawal.wallet_address}</td>
                                                            <td>Blockchain Record</td>
                                                            <td>{withdrawal.status}</td>
                                                            <td>remarks</td>
                                                            <td>{dateFormat(withdrawal.createdAt, "yyyy-mm-dd,hh:mm:ss TT")}</td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                            )) :
                                            (<tr className='text-center'>
                                                <td colSpan='10'>Data Not Found</td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>

                                {/* <div className='market-table-footer'>
                                    <div className='total-page'>
                                        <p>1-13 Of 13</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                                <li class="page-item"><a class="page-link page-link1" href=""><GrFormPrevious /></a></li>
                                                <li class="page-item"><a class="page-link page-link1" href="">1</a></li>
                                                <li class="page-item"><a class="page-link page-link1" href=""><GrFormNext /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                                <div className='market-table-footer'>
                                    {withdrawalHistory.length > 0 ? (
                                        <>
                                            <div className='total-page'>
                                                <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                            </div>
                                            <div className='pagination-div'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                <GrFormPrevious />
                                                            </a>
                                                        </li>

                                                        <li className={`page-item`}>
                                                            <a className="page-link page-link1" >
                                                                {currentPage}
                                                            </a>
                                                        </li>

                                                        <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handleNextPage}>
                                                                <GrFormNext />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </>
                                    ) : (
                                        null
                                    )
                                    }
                                </div>


                                {/* <div className='market-table-footer'>
                                    <div className='total-page'>
                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                        <GrFormPrevious />
                                                    </a>
                                                </li>

                                                <li className={`page-item `}>
                                                    <a className="page-link page-link1" >
                                                        {currentPage}
                                                    </a>
                                                </li>

                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                        <GrFormNext />
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                    {/* <WithdrawList/> */}
                </div>
            </section>

        </>
    )
}

export default WithdrawHistory