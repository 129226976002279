import React from 'react'
import refb from '../../assets/icons/rr-banner.png'
import refb1 from '../../assets/img/eok.png'
import refb3 from '../../assets/img/ref-3.png'
import refb4 from '../../assets/img/ref-5.png'
import rewards1 from '../../assets/img/rewards1.png'
import rewards2 from '../../assets/img/rewards2.png'
import rewards3 from '../../assets/img/rewards3.png'
import refc from '../../assets/img/ref_c_img.png'
import refc2 from '../../assets/img/Connect.png'
import refc3 from '../../assets/img/3d_illustrat.png'
import { NavLink } from 'react-router-dom'
const Referral = () => {
    return (
        <>
            <section className='ref_01'>
                <div className='container'>
                    <div className="row banner ref-banner">
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img ref_banner_image' src={refb}></img>
                        </div>
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Earn on Referral</h4>
                                <h2 className="w ref_title outfit">Invite A Friend to Earn Over <span className='sb'>25,000 Shiba Inu</span > directly into your Bitnetto account</h2>
                                <p className="banner_text w outfit">With these coins, you can explore our platform further and potentially grow your investment. Plus, you'll continue to enjoy the benefits and features that make Bitnetto a top choice for crypto enthusiasts. It's a win-win situation for you!</p>
                                <div class="d-flex">
                                    <NavLink to="/referral-link" className="ref_btn">Invite Friends</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row banner ref-banner">

                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Earn on KYC</h4>
                                <h2 className="w ref_title outfit">Once your KYC is successfully completed, you'll receive<span className='sb'> 1 $  ETH</span >  as a token of appreciation</h2>
                                <p className="banner_text w outfit"> It's our way of saying thank you for entrusting us with your verification process. But that's not all – you'll also gain access to exclusive security features, ensuring the safety of your assets and enhancing your trading experience</p>
                                <div class="d-flex">
                                    <NavLink to="/userdetail" className="ref_btn">Complete KYC</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img ref_banner_image' src={refb1}></img>
                        </div>
                    </div>

                    <div className="row banner ref-banner">
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img ref_banner_image' src={refb3}></img>
                        </div>
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Earn on Staking</h4>
                                <h2 className="w ref_title outfit">Invest in stability: stack USDT on BitNetto Exchange,<span className='sb'> earn 0.36% </span>daily for<span className='sb'> 18 months!</span></h2>
                                <p className="banner_text w outfit">With BitNetto, your assets are secure, thanks to our robust security measures, and our transparent operations make tracking your earnings effortless. 
                                Count on BitNetto's reliability, backed by a proven track record, to keep your investments safe. 
                                Take advantage of this chance to increase your wealth consistently and safely. 
                                Start stacking USDT on BitNetto Exchange today and enjoy the benefits of passive income. 
                                Make informed investment decisions, and keep in mind that terms and conditions apply.</p>
                                <div class="d-flex">
                                    <NavLink to="/staking" className="ref_btn">Stake Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row banner ref-banner">

                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 className="sb market_sub_title outfit">Help Us Improve And Earn Crypto Rewards!</h4>
                                <h2 className="w ref_title outfit">Report bugs and earn up to <span className='sb'>1000 crypto </span>as a bug bonus! </h2>
                                <p className="banner_text w outfit">If you encounter any bugs or errors on our platform, let us know, and we'll reward you generously with up to 1000 crypto as a bug bonus! Your feedback is invaluable in helping us enhance our platform's performance and user experience. Don't hesitate to report any issues you come across—your contribution not only helps us improve but also earns you crypto rewards. </p>
                                <div class="d-flex">
                                    <NavLink to="/bug_reports" className="ref_btn">Report a bug</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center  app-download align_end">
                            <img className='banner_img ref_banner_image' src={refb4}></img>
                        </div>
                    </div>
                </div>
            </section>
            <section className='ref_02'>
                <div className='container'>
                    <h2 className="w ref_title outfit text-center">One Invitation for Multiple Rewards</h2>
                    <div className='margin-top'></div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='rewards_box'>
                                <img className='rewards_box_img' src={rewards1}></img>
                                <h4 className='rewards_sub_title outfit sb'>Get Up to</h4>
                                <h3 className=' rewards_title outfit sb'>25,000 <span className='sb usdt'>Shiba Inu  </span></h3>
                                <p className='w banner_text outfit'>in Rewards</p>
                                <h4 className='w rewards_title2 outfit'>From inviting new friends to  join Bitnetto </h4>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='rewards_box'>
                                <img className='rewards_box_img' src={rewards2}></img>
                                <h4 className='rewards_sub_title outfit sb'>Get Up to</h4>
                                <h3 className='sb rewards_title outfit'>1 $<span className='sb usdt'>ETH </span></h3>
                                <p className='w banner_text outfit'>in Rewards</p>
                                <h4 className='w rewards_title2 outfit'>Once your KYC is successfully completed</h4>

                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='rewards_box'>
                                <img className='rewards_box_img' src={rewards3}></img>
                                <h4 className='rewards_sub_title outfit sb'>Get Up to</h4>
                                <h3 className='w rewards_title outfit'>20 <span className='sb usdt'> USDT </span></h3>
                                <p className='w banner_text outfit'>in Rewards</p>
                                <h4 className='w rewards_title2 outfit'>For Every Bybit Card Referral</h4>
                                <div class="text-center">
                                    <a href="#" className="ref_btn">Invite </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="ref_04">
                <div className='container'>
                    <h2 className="w ref_title outfit text-center">How to Invite</h2>
                    <div className='margin-top'></div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='counter_box_main'>
                                <div className='ref_counter_box text-center'>
                                    <img src={refc}></img>
                                    <h4 className='ref_counter_title w text-center outfit'>Share Your Code and Link</h4>
                                    <p className='w ref_counter_text outfit text-center'>You can invite your friends to use  Bitnetto  products with just one referral code.</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='counter_box_main'>
                                <div className='ref_counter_box text-center'>
                                    <img src={refc2}></img>
                                    <h4 className='ref_counter_title w text-center outfit'>Connect with Your Friends</h4>
                                    <p className='w ref_counter_text outfit text-center'>Your friends will be associated with you after they Sign Up.</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='counter_box_main'>
                                <div className='ref_counter_box text-center'>
                                    <img src={refc3}></img>
                                    <h4 className='ref_counter_title w text-center outfit'>Get Multiple Rewards</h4>
                                    <p className='w ref_counter_text outfit text-center'>Automatically get Trading Commissions through Bitnetto Card Rewards.</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Referral