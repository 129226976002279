import React, { useState, useEffect } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

const Sidebar = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Simulate a data fetch
    setTimeout(() => {
      setData({ /* fetched data */ });
      setLoading(false);
    }, 10000);
  }, []);



  return (
    <>

      <div>
        {loading ? (
          <div className="loader-container text-center py-5">
            <PuffLoader color="#36d7b7" />
          </div>
        ) : (
          <div>
            {/* Render your data here */}
            <p>Data loaded!</p>
          </div>
        )}
      </div>

    </>
  )
}

export default Sidebar