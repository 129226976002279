import React from 'react'
import sup from '../../assets/img/help.png'
import sup1 from '../../assets/img/mail.png'


import faqMid from '../../assets/img/sup-email.png'
import mail from '../../assets/icons/icon-1.png'
import mail1 from '../../assets/icons/icon-2.png'
import mail2 from '../../assets/icons/icon-3.png'
const Support = () => {
    return (
        <>


            <section className="faq01">
                <div className="container">
                    <div className="row banner">
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 class="sb market_sub_title outfit">Support</h4>
                                <h1 className="w banner_heading outfit">Welcome to Bitnetto Support!</h1>
                                <p className="banner_text w outfit">At Bitnetto, we're committed to providing you with the best possible experience. If you have any questions or encounter any issues while using our platform.</p>
                                <p className="banner_text w outfit">our support team is here to help. Whether you need assistance with account setup, trading, security features, or anything else, we've got you covered.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center py-3">
                            <img className='banner_img_faq' src={sup}></img>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec04">
                <div className="container">
                    <div className="mt-5 mb-5">
                        <div className="row  faq-row-flex">

                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="https://www.facebook.com/bitnetto.io">
                                                <img src={mail}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="https://www.facebook.com/bitnetto.io">www.facebook.com/bitnetto.io</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">www.facebook.com/bitnetto.io</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Facebook</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Facebook us for a swift, laser-focus support.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="https://www.youtube.com/@bitnetto">
                                                <img src={mail1}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="https://www.youtube.com/@bitnetto">www.youtube.com/@bitnetto</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">www.youtube.com/@bitnetto</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Youtube</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Youtube us for a swift, laser-focus support.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="mailto:support@bitnetto.io">
                                                <img src={mail2}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="mailto:support@bitnetto.io">support@bitnetto.io</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">support@bitnetto.io</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Email</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Email us for a swift, laser-focus support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Support
