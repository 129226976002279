import React from 'react'

const Transfer = () => {
  return (
    <>
       <div className=''>
            <h1>Transfer page</h1>
        </div> 
    
    </>
  )
}

export default Transfer