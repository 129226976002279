import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { base_url } from '../config/config';
import dateFormat, { masks } from "dateformat";

const TradeOrderHistory = () => {
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const orderId = params.get('orderId');

    const [orderHistory, setOrderHistory] = useState('');
    const [orderInfo, setOrderInfo] = useState([])

    // console.log('orderId', orderId);

    const getOrderData = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/tradingOrderInfo?orderid=${orderId}`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

            })
        });
        const data = await response.json();
        // console.log('getorderData--->', data);
        if (data.success === 1) {
            // console.log('data--12',data.wallets);
            setOrderHistory(data.tradingDetails)
            setOrderInfo(data.info)

        } else {
            console.error('Error:', response.status);
        }
    };

    useEffect(() => {
        getOrderData();
    }, []);

    // console.log('orderHistory---->', orderHistory);
    // console.log('orderInfo---->', orderInfo);

    return (
        <>
            <section className='token-history-main'>
                <div className="container">
                    <div className='mt-5 '>
                        <h4>Order Details</h4>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='order-detail-card'>
                                <li>
                                    <span className='order-title'>Type</span>
                                    <span className='order-value'>{orderHistory.orderType}</span>
                                </li>
                                <li>
                                    <span className='order-title'>Order Id</span>
                                    <span className='order-value'>{orderHistory._id}</span>
                                </li>
                                <li>
                                    <span className='order-title'>Placed On</span>
                                    <span className='order-value'>{dateFormat(orderHistory.createdAt, "dd-mm-yyyy , hh-mm-ss TT")}</span>
                                </li>

                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='order-detail-card'>

                                <li>
                                    <span className='order-title'>Filed / Amount</span>
                                    <span className='order-value'>{(orderHistory.tokenQuantity)?(orderHistory.tokenQuantity - orderHistory.tokenPendingquantity).toFixed(5):0} / {(orderHistory.tokenQuantity)?(orderHistory.tokenQuantity).toFixed(5):0} </span>
                                </li>
                                <li>
                                    <span className='order-title'>Total</span>
                                    <span className='order-value'>{(orderHistory.pairCurrencyPrice)?(orderHistory.pairCurrencyPrice * orderHistory.tokenQuantity).toFixed(5):0}</span>
                                </li>
                                <li>
                                    <span className='order-title'>Fee</span>
                                    <span className='order-value'>{'0.00'}</span>
                                </li>
                            </div>
                        </div>
                        <div className='col-md-12 mb-5'>
                            <div className='market-table'>

                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th >Data/Time </th>
                                            <th>Filled</th>
                                            <th>Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderInfo.map((order, index) => (

                                            <tr key={index}>
                                                <td>{dateFormat(order.createdAt, "dd-mm-yyyy , hh-mm-ss TT")}</td>
                                               
                                                <td>{order.quantity}</td>
                                                <td>{(order.pairCurrencyValue)?(order.pairCurrencyValue).toFixed(5):0}</td>
                                               
                                            
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                                {/* <div className='market-table-footer'>
                                    <div className='total-page'>
                                        <p>{page}-{pageCount} Of {pageCount}</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <button type="button" className="page-link page-link1" onClick={handlePrevios} disabled={page === 1}><GrFormPrevious /></button>
                                                </li>
                                                <li className="page-item"><span className="page-link page-link1">{page}</span></li>
                                                <li className="page-item">
                                                    <button type="button" className="page-link page-link1" onClick={handleNext} disabled={page === pageCount}><GrFormNext /></button>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default TradeOrderHistory