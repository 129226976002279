import React from 'react'
import { FaBars } from 'react-icons/fa'
import ProfileSidebar from './ProfileSidebar'

const ProfileTopHeader = () => {
    return (
        <>
            <div className='pt-header'>
                <div className='container'>
                    <div className='pt-row'>
                        <h5></h5>
                        <button className="btn mobile-toggle-btn pt-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions1" aria-controls="offcanvasWithBothOptions"><FaBars className='pt-btn'/></button>

                    </div>
                </div>
            </div>

            <section className='mobile-header'>
                <div class="offcanvas offcanvas-start offcanvas-body" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions1" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div class="offcanvas-header">
                       
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        
                    </div>
                    <div class="offcanvas-body ">
                        <ProfileSidebar/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProfileTopHeader

