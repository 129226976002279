import React, { useEffect, useState, useContext } from 'react'
import dateFormat, { masks } from "dateformat";
import ProfileTopHeader from './ProfileTopHeader'
import ProfileSidebar from './ProfileSidebar'
import { FaCopy } from 'react-icons/fa'
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import PuffLoader from 'react-spinners/PuffLoader';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

import { ProfileContext } from '../../Profilecontextapi';
const ReferralLink = () => {
    const { userData, loading } = useContext(ProfileContext);
    // useEffect(() => {
    //     getUserDetail();
    // }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalMember, setTotalMember] = useState();
    const [limit] = useState(10);
    const [skip] = useState(0);
    const [refId, setRefId] = useState('');
    const refLink = `https://bitnetto.io/signup?refId=${userData?.username}`;

    // console.log('userDatauserData',userData);

    const [memberData, setMemberData] = useState([]);


    const getMember = async (skip = 0) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/get_referral_member`, {
            method: "Post",
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit,
                skip,

            }),

        });
        const res = await response.json();
        // console.log(res.totalMember)
        console.log('responsemember', res.refferaldata);
        if (res.success === 1) {
            setMemberData(res.refferaldata)
            setTotalMember(res.totalMember)

        }
    };
    useEffect(() => {
        getMember();
    }, [])

    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        getMember(skip);
    }, [currentPage, limit]);



    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(totalMember / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const token = localStorage.getItem('token');
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                // Clipboard successfully copied
                // console.log('Address copied to clipboard:', text);
                toast.dismiss();
                toast.success('Share Link copied to clipboard:', text)
            })
            .catch((error) => {
                // Unable to copy to clipboard
                console.error('Error copying to clipboard:', error);
                toast.dismiss();
                toast.error('Error copying to clipboard:', error)
            });
    };
    const handleCopys = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                // Clipboard successfully copied
                // console.log('Address copied to clipboard:', text);
                toast.dismiss();
                toast.success('Referral code copied to clipboard:', text)
            })
            .catch((error) => {
                // Unable to copy to clipboard
                console.error('Error copying to clipboard:', error);
                toast.dismiss();
                toast.error('Error copying to clipboard:', error)
            });
    };

    return (
        <>

            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='ref-page-title'>
                                    <h3>Refferal & Earns</h3>
                                </div>

                                <div className='ref-Link '>
                                    <div className='share-div'>
                                        <div className='form-group form-list'>
                                            <label>Referral Code</label>
                                            <input type="text" value={userData?.username} className='form-control' />
                                            <button type='button' onClick={() => handleCopys(userData?.username)} className='btn ref-copt-btn'><FaCopy /></button>
                                        </div>
                                    </div>
                                    <div className='share-div'>
                                        <div className='form-group form-list-1 mt-4'>
                                            <label>Share Link</label>
                                            <input type="text" value={refLink} className='form-control w-100' />
                                            <button type='button' onClick={() => handleCopy(refLink)} className='btn ref-copt-btn'><FaCopy /></button>
                                        </div>

                                    </div>
                                </div>


                                <div className='ref-bonus-main'>
                                    <h3>Referral Members</h3>
                                    <div className='ref-table-main '>
                                        <table class="table ref-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S No.</th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">KYC Status</th>
                                                    <th scope="col">Date & Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {memberData.length > 0 ? memberData?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td>{(currentPage - 1) * limit + index + 1}</td>
                                                                {/* <td>{index + 1}</td> */}
                                                                <td>{item.username}</td>
                                                                <td>{item.firstname}</td>
                                                                <td>{item.kycStatus}</td>
                                                                <td>{dateFormat(item.createdAt, "dd-mm-yyyy,hh:mm:ss TT")}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                                    : (<tr className='text-center'>
                                                        <td colSpan='4'> <div className='loader-container'>
                                                            <PuffLoader color="#36d7b7" cssOverride={{ textAlign: 'center' }} size={30} />
                                                        </div> Data Not Found</td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>

                                        <div className='market-table-footer'>
                                            {memberData.length > 0 ? (
                                                <>
                                                    <div className='total-page'>
                                                        <p>Page {currentPage} of {Math.ceil(totalMember / limit)}</p>
                                                    </div>
                                                    <div className='pagination-div'>
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination">
                                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                        <GrFormPrevious />
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item`}>
                                                                    <a className="page-link page-link1" >
                                                                        {currentPage}
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item ${currentPage === Math.ceil(totalMember / limit) ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                                        <GrFormNext />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>

                                                </>
                                            ) : (
                                                null
                                            )
                                            }
                                        </div>

                                        {/* <div className='market-table-footer'>
                                            {memberData.length > 0 ? (
                                                <>
                                                    <div className='total-page'>
                                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                                    </div>
                                                    <div className='pagination-div'>
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination">
                                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                        <GrFormPrevious />
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item`}>
                                                                    <a className="page-link page-link1" >
                                                                        {currentPage}
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                                        <GrFormNext />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </>
                                            ) : (
                                                null
                                            )
                                            }
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReferralLink