import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const OverHeader = () => {
    let location = useLocation();
    return (
        <>
            <section className='inner-header'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inner-menu'>
                                <div className='inner-menu-sm'>
                                    <ul>
                                        <li className={location.pathname === "/overview" && "active-lish-history"}>
                                            <NavLink to="/overview">Assets Overview</NavLink>
                                        </li>

                                        <li className={location.pathname === "/mainaccount" && "active-lish-history"}>
                                            <NavLink to="/mainaccount">Main Account</NavLink>
                                        </li>
                                        <li className={location.pathname === "/tradingaccount" && "active-lish-history"}>
                                            <NavLink to="/tradingaccount">Trading Account</NavLink>
                                        </li>
                                        <li className={location.pathname === "/fundingaccount" && "active-lish-history"}>
                                            <NavLink vLink to="/fundingaccount">Funding Account</NavLink>
                                        </li>
                                        <li className={location.pathname === "/transactionshistort" && "active-lish-history"}>
                                            <NavLink vLink to="/transactionshistort">Transfer History</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OverHeader