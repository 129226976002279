// import React, { useEffect, useState } from 'react';

// const TradingPage = ({ symbol }) => {
//   const [latestPrice, setLatestPrice] = useState(null);
//   const [orderBookData, setOrderBookData] = useState([]);
//   const [latestAmount, setLatestAmount] = useState(null);
//   const [latestTotal, setLatestTotal] = useState(null);
//   let Symbol = `${symbol.toLowerCase()}usdt`;
//   console.log('Symbol--',Symbol);
//   let previousPrice = 0;

//   // Function to handle WebSocket messages
//   const handleWebSocketMessage = (message) => {
//     try {
//       const data = JSON.parse(message.data);
//       // console.log(data);
//       const total=parseInt(data.T)
//       const price = parseFloat(data.p);
//       const amount = parseFloat(data.t);

//       setLatestPrice(price);
//       setLatestAmount(total);
//       setLatestTotal(amount);
//       setOrderBookData((prevData) => [...prevData, { price: price, total: total, amount: amount }]);

//       previousPrice = price;
//     } catch (error) {
//       console.error("Error parsing WebSocket message:", error);
//     }
//   };

//   // Function to establish WebSocket connection
//   const establishWebSocketConnection = () => {
//     const token = Symbol;
//     const ws = new WebSocket(`wss://fstream.binance.com/ws/${token}@trade`);

//     // Handle WebSocket messages
//     ws.onmessage = handleWebSocketMessage;

//     // Close WebSocket connection on component unmount
//     return () => {
//       ws.close();
//     };
//   };

//   // Effect to establish WebSocket connection
//   useEffect(() => {
//     const cleanupWebSocket = establishWebSocketConnection();
//     return () => {
//       cleanupWebSocket();
//     };
//   }, [Symbol]);

//   // Function to get the price change class
//   const getPriceChangeClass = (price) => {
//     if (price > previousPrice) {
//       return 'price-up';
//     } else if (price < previousPrice) {
//       return 'price-down';
//     } else {
//       return '';
//     }
//   };

//   return (
//     <>
//       <div className='orderbook-card'>
//         <div className='order-header'>
//           <h4>Order Book</h4>
//         </div>
//         <div className='group-div'>
//           <p>Group By</p>
//           <div className=''>
//             <select className='order-select'>
//               <option>0.00001</option>
//               <option>0.0001</option>
//               <option>0.001</option>
//               <option>0.01</option>
//             </select>
//           </div>
//         </div>
//         <div className='trade-table1 table-scroll2'>
//           <table className="table tradeing-table-row">
//             <thead>
//               <tr>
//                 <th scope="col">Price ({Symbol})</th>
//                 <th scope="col">Amount ({Symbol})</th>
//                 <th scope="col">Total (USD)</th>
//               </tr>
//             </thead>
//             <tbody>
//               {orderBookData.slice().reverse().map((data, index) => (
//                 <tr key={index} className={getPriceChangeClass(data.price)}>
//                   <td>{data.price}</td>
//                   <td>{data.total}</td>
//                   <td>{data.amount}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </>
//   );
// };

// export default TradingPage;




import React, { useEffect, useState } from 'react';

const TradingPage = ({ symbol }) => {
  // console.log('symbol----',symbol);
  // State variables to store latest price, order book data, latest amount, and latest total
  const [latestPrice, setLatestPrice] = useState(null);
  const [orderBookData, setOrderBookData] = useState([]);
  const [latestAmount, setLatestAmount] = useState(null);
  const [latestTotal, setLatestTotal] = useState(null);

  // Function to handle WebSocket messages
  const handleWebSocketMessage = (message) => {
    // console.log('message-------------',message);
    try {
      const data = JSON.parse(message.data);
      const total = parseInt(data.T);
      const price = parseFloat(data.p);
      const amount = parseFloat(data.t);
      // console.log('price---', price);
      // Update state variables with latest data
      setLatestPrice(price);
      setLatestAmount(total);
      setLatestTotal(amount);
      // Add new order book data to existing data
      setOrderBookData((prevData) => [...prevData, { price: price, total: total, amount: amount }]);
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
    }
  };

  // Effect to establish WebSocket connection
  useEffect(() => {
    // Form the token for WebSocket connection
    const token = symbol.toLowerCase() + 'usdt'; // Ensure lowercase and add 'usdt'
    // Establish WebSocket connection
    // const ws = new WebSocket(`wss://fstream.binance.com/ws/${token}@trade`);
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${token}@trade`);
    // Handle WebSocket messages
    ws.onmessage = handleWebSocketMessage;

    // Close WebSocket connection on component unmount
    return () => {
      ws.close();
    };
  }, [symbol]); // Watch symbol for changes

  // Function to get the price change class
  const getPriceChangeClass = (price) => {
    // Comparing with the latest price instead of previousPrice
    if (price > latestPrice) {
      return 'price-up';
    } else if (price < latestPrice) {
      return 'price-down';
    } else {
      return '';
    }
  };

  return (
    <>
      <div className='orderbook-card'>
        <div className='order-header'>
          <h4>Order Book</h4>
        </div>
        <div className='group-div'>
          <p>Group By</p>
          <div className=''>
            <select className='order-select'>
              <option>0.00001</option>
              <option>0.0001</option>
              <option>0.001</option>
              <option>0.01</option>
            </select>
          </div>
        </div>
        <div className='trade-table1 table-scroll2'>
          <table className="table tradeing-table-row">
            <thead>
              <tr>
                <th scope="col">Price ({symbol.toUpperCase()}USDT)</th> {/* Displaying symbol in header */}
                <th scope="col">Amount ({symbol.toUpperCase()}USDT)</th>
                <th scope="col">Total (USD)</th>
              </tr>
            </thead>
            <tbody>
              {/* Mapping through order book data and rendering rows */}
              {orderBookData.slice().reverse().map((data, index) => (
                <tr key={index} className={getPriceChangeClass(data.price)}>
                  <td>{data.price}</td>
                  <td>{data.total}</td>
                  <td>{data.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TradingPage;
